/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {
  AnimButtonStatesEnum,
  EditorModeEnums,
  IEditorLock,
  SpeakersModalTypeEnum,
  UserRoleEnums,
} from '../../redux/store/IStore';

import {
  CurrentCommandModeEnums,
  IFinalBlock,
  ILastSaved,
  ILoopModeOpts,
  IModal,
  IReplaceWordsModalProps,
  ISpeaker,
  IWordDataLive,
  ModalVariantsEnums,
} from './IEditor';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import SelectionPopover from '../SelectionPopover/SelectionPopover';
import { useNavigate } from 'react-router-dom';
import useShortcuts from '../../hooks/useShortcuts';
import { convertFromRaw, convertToRaw, EditorState, Modifier, RawDraftContentBlock, RawDraftContentState, RichUtils, SelectionState } from 'draft-js';
import {
  customBlockStyleFn,
  decorator,
  getAllEntitiesKeysFromSelection,
  replaceSelectionWithNewText,
} from './helpers/EditorHelpers';
import useSelectionPopover from '../../hooks/useSelectionPopover';
import { IDictionaryModalProps } from '../DictionaryModal/IDictionaryModal';
import { convertEditorStateToTranscript, convertTranscriptToEditorState } from '../../shared/DataConverters';
import '../../styles/css/editor.css';
import { useBeforeunload } from 'react-beforeunload';
import useWs from '../../hooks/useWs';
import useAudioSocket from '../../hooks/useAudioSocket';
import useEditorRefs from '../../hooks/useEditorRefs';
import LiveEditor from './LiveEditor';
import useEditor from '../../hooks/useEditor';
import useDraftJsFns from '../../hooks/useDraftJsFns';
import ModalsWrapper from './ModalsWrapper';
import { AnimatePresence } from 'framer-motion';
import { IReplacementWord } from '../../api/ReplacementsService';
import { debounce } from 'lodash';
import {
  deleteEditorContent,
  deleteRecording,
  getEditorContentV30,
  getNewSessionLock,
  getSession,
  patchEditorContentV30,
  unlockSession,
} from '../../api/SessionsService';
import CustomEditor from './CustomEditor';
import { convertToHTML } from 'draft-convert';
import { useSnackbar } from 'notistack';
import useV3Ws from '../../hooks/useV3Ws';
import useInterval from '../../hooks/useInterval';
import useKeycloak from '../../hooks/useKeycloak';
import useModelStatus from '../../hooks/useModelStatus';
import { AccessMode, ISpeakerRange } from '../../types';
import usePipeline from '../../hooks/usePipeline';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setAnimButtonState, setAudioInfo, setClickedTime, setCurrentTime, setDiarization, setEditorLock, setEditorMode, setHeaderIsExtended, setHomeFlowState, setIsSessionDiscarded, setLoadContentToEditor, setManuallyUpdateEditorState, setSessionName, setShowConfidence, setSpeakersModal, setTbFormat, setTriggerStopRecording, setUploadedFileDuration, setValidRedirect } from '../../redux/features/app/app';
import { abbreviations } from './constants';


const LOWER_WORD_LIMIT = 200;
const UPPER_WORD_LIMIT = 300;
const SECOND_LIMIT = 2;

const isNumber = (text: string) => /^[0-9]+$/.test(text);
const isPunctuation = (text: string) => /[.?!:;]/.test(text);
const isAbbreviation = (text: string) => abbreviations.includes(text);

export const covertEditorStateToHTML = (editorState: EditorState) => {
  let counter = 0;
  let blockSpeakers = new Set();
  let blockSpeakersArrInit: any[] = [];

  // Currentlyjust using convertToHTML only to get styles efficiently out and then adding speakers after
  const html = convertToHTML({
    styleToHTML: (style) => {
      if (style === 'BOLD') {
        return <span style={{ fontWeight: 'bold' }} />;
      }
      if (style === 'ITALIC') {
        return <span style={{ fontStyle: 'italic' }} />;
      }
      if (style === 'UNDERLINE') {
        return <span style={{ textDecoration: 'underline' }} />;
      }
    },
    blockToHTML: (block) => {
      if (block.type === 'customBlock') {
        counter++;

        if (block.data?.speaker && counter % 2 !== 0) {
          blockSpeakers.add(block.data.speaker);
          blockSpeakersArrInit.push(block.data.speaker);
        }
        return <p />;
        //   <div>
        //     <span>{block.data?.speaker?.name}</span>
        //     <span>{block.text}</span>
        //   </div>
        // );
      }
    },
    entityToHTML: (entity, originalText) => {
      // if (entity.type === 'WORD') {
      //   TO-DO: if we want to add any additional stuff to our word entities
      // }
      return originalText;
    },
  })(editorState.getCurrentContent());

  if (blockSpeakersArrInit.length) {
    let prevSpeaker: any = null;

    const paragraphsHtml = html
      .split('</p>')
      .slice(0, -1)
      .map((p, i) => {
        const speaker = blockSpeakersArrInit[i];

        const prevBlockIsSame =
          speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
            ? prevSpeaker.id === speaker.id
            : false;

        prevSpeaker = speaker;

        if (!prevBlockIsSame) {
          const spakerString = `<h1>${speaker.name}</h1>`;
          return `${spakerString}${p}</p>`;
        }
        return `${p}</p>`;
      })
      .join('');

    return paragraphsHtml;
  }

  return html;
};

const newSpeaker: ISpeaker = {
  name: 'Neznani govorec',
  id: -1,
  labels: [],
};

const Editor: FC = () => {
  const {
    setLiveWordData,
    setCursor,
    setFixSpellCommandMode,
    setFindCommandMode,
    liveWordData,
    transcriptData,
    footerTr,
    cursor,
    editorState,
    setEditorState,
  } = useEditor();

  const {
    isAuthenticated
  } = useKeycloak();

  const speakersDefined = useRef(false);
  const [showSpeakers, setShowSpeakers] = useState(false);

  const [modal, setModal] = useState<IModal>({ show: false, variant: ModalVariantsEnums.EXIT });
  const savedModalVariant = useRef<ModalVariantsEnums>(ModalVariantsEnums.EXIT);
  const [audioCanPlay, setAudioCanPlay] = useState<boolean>(false);
  const [willRecordInActiveSessionId, setWillRecordInActiveSessionId] = useState<number | null>(null);
  const [lastSaved, setLastSaved] = useState<ILastSaved>({
    lastSaveSuccess: true,
    lastSavedTs: null,
    isSaving: false,
  });
  const [showEditor, setShowEditor] = useState<boolean>(true);
  const [spellCheck, setSpellCheck] = useState<boolean>(false);
  const [dictionaryModalProps, setDictionaryModalProps] = useState<IDictionaryModalProps>({
    open: false,
    word: undefined,
  });
  const [replaceWordsModalProps, setReplaceWordsModalProps] = useState<IReplaceWordsModalProps>({
    selectedPhrase: [],
    se: null,
    selection: null,
  });
  const [loopModeOptions, setLoopModeOptions] = useState<ILoopModeOpts>({
    isActive: false,
    startTime: 0,
    endTime: 0,
  });
  const [loadingEditorDetails, setLoadingEditorDetails] = useState(false);
  const [canDiscard, setCanDiscard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(state => state.app.user);
  const validRedirect = useAppSelector(state => state.app.validRedirect);
  const editorLock = useAppSelector(state => state.app.editorLock);
  const speakerModal = useAppSelector(state => state.app.speakersModal);
  const updateEditorState = useAppSelector(state => state.app.manuallyUpdateEditorState);
  const headerIsExtended = useAppSelector(state => state.app.headerIsExtended);
  const editorMode = useAppSelector(state => state.app.editorMode);
  const fontSize = useAppSelector(state => state.app.fontSize);
  const tbFormat = useAppSelector(state => state.app.tbFormat);
  const showConfidence = useAppSelector(state => state.app.showConfidence);
  const loadContentToEditor = useAppSelector(state => state.app.loadContentToEditor);
  const isSessionDiscarded = useAppSelector(state => state.app.isSessionDiscarded);
  const diarization = useAppSelector(state => state.app.diarization);
  const triggerStopRecording = useAppSelector(state => state.app.triggerStopRecording);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectionPopoverProps, closeSelectionPopover } = useSelectionPopover(showEditor);
  //const { isAvailable: currentModelAvailable, fetchCurrentModelStatus } = useCheckIfModelAvailable();
  const { getModelStatus } = useModelStatus();
  const { editorRef, topScrollerRef, isInFindMode, isNewRecordingSession, hasEditorContentChanged } =
    useEditorRefs();

  const {
    lastSessionInfo,
    resetUpdateAudioToken,
  } = useWs();

  const {
    recordingsIdsInCurrentEditorSession,
    resetLastMessage,
    closeWs,
  } = useV3Ws();

  //const { releaseAudioContext } = useAudioSocket();

  const savedEditorContent = useRef<any>(null);
  const lastEditorState = useRef<EditorState | null>(null);
  const lastWillRecordInActiveSessionId = useRef<number | null>(null);
  const lastSessionId = useRef<number | null>(null);
  const editorContentId = useRef<number | null>(null);

  const { handleBeforeInput, onEditorChange, handlePastedText } = useDraftJsFns({
    editorState,
    setEditorState,
    hasEditorContentChanged,
  });

  const saveCurrentSession = useCallback(
    debounce(
      async ({
        liveWordData,
        editorState,
        editorLock,
      }: {
        liveWordData?: IWordDataLive;
        editorState?: EditorState;
        editorLock?: IEditorLock | null;
      }) => {
        if (savedModalVariant.current === ModalVariantsEnums.DISCARD || !hasEditorContentChanged.current) {
          return;
        }

        setLastSaved((prev) => {
          return {
            ...prev,
            isSaving: true,
          };
        });

        try {
          if (editorState && editorLock && editorLock.sessionLockKey && editorLock.editTicket && editorLock.sessionId) {
            const r = await patchEditorContentV30(
              { rawContentState: convertToRaw(editorState.getCurrentContent()) },
              editorLock.sessionId,
              editorLock.editTicket,
              editorLock.sessionLockKey,
            );

            if (r.status === 204 || r.status === 201) {
              setLastSaved({
                lastSavedTs: Date.now(),
                isSaving: false,
                lastSaveSuccess: true,
              });

              setCanDiscard(true);
              /*const { id } = r.data;
              if (typeof id === 'number') {
                setCanDiscard(true);
                editorContentId.current = id;
              }*/
            } else {
              // handle not saved
              setLastSaved((prev) => {
                return {
                  ...prev,
                  isSaving: false,
                  lastSaveSuccess: false,
                };
              });
            }
          }

          hasEditorContentChanged.current = false;
        } catch (e) {
          setLastSaved((prev) => {
            return {
              ...prev,
              isSaving: false,
              lastSaveSuccess: false,
            };
          });
        }
      },
      2500
    ),
    []
  );

  const isInitMount = useRef(true);
  const initEditorState = useRef<EditorState | null>(null);

  useEffect(() => {
    if (
      editorMode === EditorModeEnums.RECORDING_MODE ||
      editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
    ) {
      initEditorState.current = editorState;
      isInitMount.current = false;
    }
  }, [editorMode]);

  useEffect(() => {
    if (isInitMount.current && editorState.getCurrentContent().getPlainText() === '') {
      return;
    }
    if (isInitMount.current) {
      initEditorState.current = editorState;
      isInitMount.current = false;
    }

    if (!initEditorState.current) return;

    if (
      initEditorState.current.getCurrentContent().getPlainText() ===
      editorState.getCurrentContent().getPlainText()
    )
      return;

    if (
      (modal.variant === ModalVariantsEnums.EXIT || modal.variant === ModalVariantsEnums.DISCARD) &&
      modal.show === true
    )
      return;

    initEditorState.current = editorState;
    setLastSaved((prev) => {
      return {
        ...prev,
        isSaving: true,
      };
    });


    hasEditorContentChanged.current = true;
    saveCurrentSession({
      editorState: editorState,
      editorLock
    });
  }, [editorState, lastSessionInfo?.sessionId, willRecordInActiveSessionId]);

  useEffect(() => {
    if (editorLock.sessionId && editorLock.sessionLockKey && editorLock.editTicket) {
      saveCurrentSession({
        editorState: editorState,
        editorLock
      })
    }
  }, [editorLock])

  const handleAutoSave = (editorState: EditorState) => {
    initEditorState.current = editorState;
    setLastSaved((prev) => {
      return {
        ...prev,
        isSaving: true,
      };
    });

    /*saveCurrentSession({
      editorState: editorState,
      sessionId:
        willRecordInActiveSessionId !== null ? willRecordInActiveSessionId : lastSessionInfo?.sessionId,
    });*/

    saveCurrentSession({
      editorState: editorState,
      editorLock
    })
  };

  useEffect(() => {
    return () => {
      recordingsIdsInCurrentEditorSession.current = [];
      savedEditorContent.current = null;
      //releaseAudioContext();
      closeWs();
      dispatch(setEditorMode(null));
      dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
      dispatch(setUploadedFileDuration(null));
      resetLastMessage();
      resetUpdateAudioToken();
      dispatch(setValidRedirect(false));
      dispatch(setAudioInfo({ url: '', loadNew: false }));
      dispatch(setSessionName(''));
      dispatch(setIsSessionDiscarded(false));
      dispatch(setTbFormat({}));
      dispatch(setShowConfidence(false));
      dispatch(setClickedTime(null));
      dispatch(setHeaderIsExtended(true));
      /*if (editorLock.sessionId) {
        unlockSession(editorLock.sessionId, editorLock.sessionLockKey);
      }*/
      dispatch(
        setLoadContentToEditor({
          recFinishedStartLoadingNewEditorState: false,
          recStartedLoadTextFromEditor: false,
        })
      );
      dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }))
      dispatch(setCurrentTime(0));
      dispatch(setEditorLock({
        sessionLockKey: null,
        sessionId: null,
        recordingId: null,
        refreshAfter: null,
        editTicket: null,
        versionName: null,
      }))
    };
  }, []);

  useEffect(() => {
    const { update, newText, rangeToReplace, entityToMerge, blockKey } = updateEditorState;

    if (!update || !newText || !rangeToReplace || !entityToMerge) return;

    dispatch(
      setManuallyUpdateEditorState({
        update: false,
      })
    );

    const newState = replaceSelectionWithNewText(
      editorState,
      editorState.getSelection(),
      newText,
      rangeToReplace,
      entityToMerge,
      blockKey
    );

    newState.getCurrentContent().mergeEntityData(entityToMerge, {
      text: newText,
      updatedText: newText,
      metadata: { source: undefined, original: undefined },
    });
    setEditorState(newState);
  }, [updateEditorState]);

  useEffect(() => {
    if (!selectionPopoverProps.anchorRect) {
      setLoopModeOptions({
        isActive: false,
        startTime: 0,
        endTime: 0,
      });
    } else {
    }
  }, [selectionPopoverProps]);


  const [isModelAvailable, setIsModelAvailable] = useState<boolean>(false)

  useEffect(() => {
    if (editorMode !== EditorModeEnums.EDIT_MODE) return;

    let update = true;
    const wrapper = async () => {
      try {
        const status = await getModelStatus();

        update && setIsModelAvailable(status.isUpdatable && !status.isUpdating)
      } catch (error) {
        console.log(error)
        update && setIsModelAvailable(false)
      }
    }

    wrapper();

    return () => {
      update = false;
    }
  }, [editorMode]);

  const [processDiarization, setProcessDiarization] = useState<boolean>(false);
  useEffect(() => {
    const wrapper = async () => {
      const rawContent = convertToRaw(editorState.getCurrentContent())
      let previousSpeaker: string = ""
      let speakerText = ""
      let speakerStart = 0
      let speakerEnd = 0
      let latestSpeaker: string | null  = null;

      let speakersArray: ISpeakerRange[] = []

      //speakerIdentify
      const speakerIdentifyStart = Date.now()
      let tokenCount = 0;


      const entityRanges = rawContent.blocks[0].entityRanges;
      const entityMap = rawContent.entityMap;

      for (let entityIndex = 0; entityIndex < entityRanges.length; entityIndex++) {
        tokenCount++;
        const currentEntity = entityRanges[entityIndex]

        const {
          speakerCode: currentSpeakerCode,
          spaceBefore: currentSpaceBefore,
          endTime: currentEndTime,
          text: currentText,
        } = entityMap[currentEntity.key].data

        const isSameSpeaker = previousSpeaker === currentSpeakerCode

        if (LOWER_WORD_LIMIT < tokenCount && tokenCount < UPPER_WORD_LIMIT) {
          console.log(`Token count is ${LOWER_WORD_LIMIT} : ${tokenCount} : ${UPPER_WORD_LIMIT}`)
          console.log(`It is ${isSameSpeaker ? '' : 'NOT'} same speaker`)

          if (!isSameSpeaker) {
            const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
            
            if (previousOccurance.length === 0) {
              speakersArray.push({
                id: +`-${Date.now()}${entityIndex}`,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: previousSpeaker,
                text: speakerText
              })
            } else {
              if (latestSpeaker)
              speakersArray.push({
                id: previousOccurance[0].id,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
                text: speakerText
              })
            }

            latestSpeaker = previousSpeaker;
            speakerStart = 0
            speakerEnd = currentText.length
            speakerText = currentText
            tokenCount = 0;
            continue;
          }


          if (currentSpaceBefore) {
            speakerText += " "
            speakerEnd += 1
          }
          speakerText += currentText
          speakerEnd += currentEntity.length

          if (entityIndex === 0) {
            continue;
          }

          const previousEntity = entityRanges[entityIndex - 1];
          const {
            text: previousText,
          } = entityMap[previousEntity.key].data

          if (!isPunctuation(currentText) || isNumber(previousText) || isAbbreviation(previousText)) {
            continue;
          }

          const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
          if (previousOccurance.length === 0) {
            speakersArray.push({
              id: +`-${Date.now()}${entityIndex}`,
              rangeStart: speakerStart,
              rangeEnd: speakerEnd,
              speaker: previousSpeaker,
              text: speakerText
            })
          } else {
            speakersArray.push({
              id: previousOccurance[0].id,
              rangeStart: speakerStart,
              rangeEnd: speakerEnd,
              speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
              text: speakerText
            })
          }

          latestSpeaker = previousSpeaker;
          speakerStart = 0
          speakerEnd = 0
          speakerText = ''
          tokenCount = 0;
        } else if (tokenCount >= UPPER_WORD_LIMIT) {
          console.log(`Token count is ${tokenCount} : ${UPPER_WORD_LIMIT}`)
          console.log(`It is ${isSameSpeaker ? '' : 'NOT'} same speaker`)

          if (!isSameSpeaker) {
            const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
            if (previousOccurance.length === 0) {
              speakersArray.push({
                id: +`-${Date.now()}${entityIndex}`,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: previousSpeaker,
                text: speakerText
              })
            } else {
              speakersArray.push({
                id: previousOccurance[0].id,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
                text: speakerText
              })
            }

            latestSpeaker = previousSpeaker;
            speakerStart = 0
            speakerEnd = currentEntity.length
            speakerText = currentText
            tokenCount = 0;
            continue;
          }

          if (currentSpaceBefore) {
            speakerText += " "
            speakerEnd += 1
          }
          speakerText += currentText
          speakerEnd += currentEntity.length

          console.log(`entityIndex is: ${entityIndex}`)
          console.log(`lastEntityIndex is: ${entityRanges.length}`)

          if (entityIndex === entityRanges.length - 1) {
            continue;
          }

          const nextEntity = entityRanges[entityIndex + 1]
          const {
            startTime: nextStartTime
          } = entityMap[nextEntity.key].data

          console.log(`Difference between next start and current end is: ${nextStartTime - currentEndTime}`)
          console.log(`Second limit is: ${SECOND_LIMIT}`)
          if (nextStartTime - currentEndTime < SECOND_LIMIT) {
            continue;
          }

          const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
          console.log(`previousOccurance is: ${previousOccurance}`)
          if (previousOccurance.length === 0) {
            speakersArray.push({
              id: +`-${Date.now()}${entityIndex}`,
              rangeStart: speakerStart,
              rangeEnd: speakerEnd,
              speaker: previousSpeaker,
              text: speakerText
            })
          } else {
            speakersArray.push({
              id: previousOccurance[0].id,
              rangeStart: speakerStart,
              rangeEnd: speakerEnd,
              speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
              text: speakerText
            })
          }

          latestSpeaker = previousSpeaker;
          speakerStart = 0
          speakerEnd = 0
          speakerText = ''
          tokenCount = 0;

        } else {
          if (entityIndex === 0 || previousSpeaker === currentSpeakerCode) {
            if (currentSpaceBefore) {
              speakerText += " "
              speakerEnd += 1
            }
            speakerText += currentText
            speakerEnd += currentEntity.length
          } else {
            const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
            if (previousOccurance.length === 0 && speakerText.length !== 0) {
              speakersArray.push({
                id: +`-${Date.now()}${entityIndex}`,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: previousSpeaker,
                text: speakerText
              })
            } else if (speakerText.length !== 0) {
              speakersArray.push({
                id: previousOccurance[0].id,
                rangeStart: speakerStart,
                rangeEnd: speakerEnd,
                speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
                text: speakerText
              })
            }

            latestSpeaker = previousSpeaker;
            speakerStart = 0
            speakerEnd = currentEntity.length
            speakerText = currentText
            tokenCount = 0;
          }

          previousSpeaker = currentSpeakerCode
        }
      }

      const previousOccurance = speakersArray.filter(speaker => speaker.speaker === previousSpeaker)
      if (previousOccurance.length === 0) {
        speakersArray.push({
          id: +`-${Date.now()}${rawContent.blocks[0].entityRanges.length}`,
          rangeStart: speakerStart,
          rangeEnd: speakerEnd,
          speaker: previousSpeaker,
          text: speakerText
        })
      } else {
        speakersArray.push({
          id: previousOccurance[0].id,
          rangeStart: speakerStart,
          rangeEnd: speakerEnd,
          speaker: latestSpeaker === previousSpeaker ? null : previousSpeaker,
          text: speakerText
        })
      }

      console.log(`Speaker identify took ${Date.now() - speakerIdentifyStart}ms`)
      
      let updatedState: EditorState = editorState
      const blockSplitStart = Date.now()
      let contentState = updatedState.getCurrentContent()
      for (let i = 0; i < speakersArray.length; i++) {
        const speakerEnd = speakersArray[i].rangeEnd
        const blocks = contentState.getBlocksAsArray()

        const blockText = blocks[blocks.length - 1].getText()
        const blockKey = blocks[blocks.length - 1].getKey()

        const add = blockText.charAt(speakerEnd) === " " ? 1 : 0
        const newSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: speakerEnd,
          focusKey: blockKey,
          focusOffset: speakerEnd + add,
          isBackward: false,
          hasFocus: false,
        });

        contentState = Modifier.splitBlock(contentState, newSelection)
      }
      updatedState = EditorState.push(updatedState, contentState, 'split-block');

      console.log(`Block split took ${Date.now() - blockSplitStart}ms`)

      const speakerAssignStart = Date.now()
      const rawUpdatedContent = convertToRaw(updatedState.getCurrentContent())
      for (let i = 0; i < speakersArray.length; i++) {
        const speakerName = speakersArray[i].speaker
        const speakerId = speakersArray[i].id

        rawUpdatedContent.blocks[i].text = rawUpdatedContent.blocks[i].text.trim()
        rawUpdatedContent.blocks[i].data = {
          speaker: {
            id: speakerId,
            name: speakerName,
          },
        }
      }
      console.log(rawUpdatedContent)
      const newContent = convertFromRaw(rawUpdatedContent);
      updatedState = EditorState.push(updatedState, newContent, 'change-block-data');

      console.log(`Speaker assign took ${Date.now() - speakerAssignStart}ms`)
      setEditorState(updatedState)
      //handleAutoSave(updatedState)
      try {
        if (!editorLock.sessionId || !editorLock.editTicket || !editorLock.sessionLockKey) return;
        const sessionData = (await getSession(editorLock.sessionId)).data
        const tag = sessionData.versionTags[0]

        const content = (await getEditorContentV30(editorLock.sessionId, editorLock.sessionLockKey, AccessMode.READ_WRITE, tag)).data;

        dispatch(setEditorLock({...editorLock, editTicket: content.editTicket}))
        
        await patchEditorContentV30(
          { rawContentState: convertToRaw(updatedState.getCurrentContent()) },
          editorLock.sessionId,
          content.editTicket,
          editorLock.sessionLockKey,
      );
      } catch (error) {
        console.log(error)
      }
      dispatch(setDiarization(false))
      setProcessDiarization(false)
    }
    if (processDiarization) {
      wrapper();
    }

  }, [processDiarization])


  // Load data into Draft.js
  useEffect(() => {
    // Load editor state after upload
    if (
      (editorMode === EditorModeEnums.EDIT_MODE || editorMode === EditorModeEnums.TB_UPLOAD_MODE) &&
      editorState.getCurrentContent().getPlainText() === ''
    ) {
      const { editorStateStringified, editorState: editorStateFromTBFormat, wordData, sessionId, transcript, rawContentState } =
        tbFormat;

      if (sessionId) {
        setWillRecordInActiveSessionId(sessionId);
      }

      if (editorStateStringified) {
        const newState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(editorStateStringified)),
          decorator
        );
        setEditorState(newState);
        savedEditorContent.current = newState;
      } else if (rawContentState) {
        const newState = EditorState.createWithContent(convertFromRaw(rawContentState), decorator);
        setEditorState(newState);
        savedEditorContent.current = newState;
      } else if (editorStateFromTBFormat) {
        setEditorState(editorStateFromTBFormat);
        if (diarization) {
          setProcessDiarization(true)
        }
        savedEditorContent.current = editorStateFromTBFormat;
      } else if (wordData) {
        const newData: IFinalBlock[] = wordData.map((words) => {
          return { words, speaker: null };
        });
        const newEditorState = convertTranscriptToEditorState(undefined, false, newData);
        setEditorState(newEditorState);
        savedEditorContent.current = newEditorState;
      } else if (transcript) {
        const newData: IFinalBlock[] = transcript.map((words) => {
          return { words, speaker: null };
        });
        const newEditorState = convertTranscriptToEditorState(undefined, false, newData);
        setEditorState(newEditorState);
        savedEditorContent.current = newEditorState;
      } else if (editorState.getCurrentContent().getPlainText() === '') {
        setEditorState(EditorState.createEmpty())
        savedEditorContent.current = editorState;
      } else {
        navigate('/');
        return;
      }

      setShowEditor(true);
      dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
    }
  }, [editorMode]);

  useEffect(() => {
    if (!showEditor && editorRef.current) {
      //TO-DO: handle blur
      editorRef.current.blur();
    }

    if (showEditor && topScrollerRef.current) {
      //TO-DO: handle scrolling
      // topScrollerRef.current.scrollIntoView();
    }
  }, [showEditor, editorRef, topScrollerRef]);

  const isEditorFocused = useRef(false);

  const [editorSelectionUpdateOnMount, setEditorSelectionUpdateOnMount] = useState<{
    offset: number;
    blockKey: string;
  } | null>(null);

  useEffect(() => {
    const wrapper = async () => {
      if (triggerStopRecording) {
        await stopRecording();
        dispatch(setTriggerStopRecording(false))
      }
    }
    wrapper();
  }, [triggerStopRecording])

  useEffect(() => {
    if (loadContentToEditor.recFinishedStartLoadingNewEditorState) {
      setLoadingEditorDetails(true);
      dispatch(
        setLoadContentToEditor({
          ...loadContentToEditor,
          recFinishedStartLoadingNewEditorState: false,
        })
      );

      setFixSpellCommandMode({
        currentCommandMode: CurrentCommandModeEnums.INIT,
        isSpellingOn: false,
        currentSpelledWord: [],
        selectedWordOriginalText: null,
        selectedWordIndexes: null,
        textToFetch: '',
        numberOfWordsInFixMode: 1,
        prevWordsHistory: null,
      });
      setFindCommandMode({
        findCommandModeOn: false,
        selectedWordIndexes: null,
        searchedText: null,
        selectedWordData: null,
        selectedRangeLength: 1,
      });
      isInFindMode.current = false;

      let newEditorState = convertTranscriptToEditorState(
        undefined,
        false,
        liveWordData.finalsBlocks,
        transcriptData,
        false,
        editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
      );

      if (cursor.cursorPosition) {
        const finalW = cursor.cursorPosition[1];
        let offset = 0;

        liveWordData.finalsBlocks[cursor.cursorPosition[0]].words.forEach((w, i) => {
          if (i <= finalW) {
            const fT = w.updatedText || w.text;
            offset += fT.length + (w.spaceBefore ? 1 : 0);
          }
        });
        setEditorSelectionUpdateOnMount({
          offset,
          blockKey: newEditorState.getCurrentContent().getBlocksAsArray()[cursor.cursorPosition[0]].getKey(),
        });
      }

      setCursor((curr) => {
        return {
          ...curr,
          cursorPosition: null,
        };
      });

      setEditorState(newEditorState);
      setShowEditor(true);
      setLoadingEditorDetails(false);
    }

    if (loadContentToEditor.recStartedLoadTextFromEditor) {
      setLoadingEditorDetails(true);
      dispatch(setLoadContentToEditor({ ...loadContentToEditor, recStartedLoadTextFromEditor: false }));

      const sel = editorState.getSelection();
      const lastBlock = editorState.getCurrentContent().getLastBlock();

      const selBlock = sel.getAnchorKey();
      const pos = sel.getAnchorOffset();
      const bl = lastBlock.getText().length;
      const cursorIsAtLastBlock = lastBlock.getKey() === selBlock;
      const cursorisAtEnd = pos === bl;
      const isLastBlockAtEnd = cursorIsAtLastBlock && cursorisAtEnd;
      const isCollapsed = sel.isCollapsed();
      const shouldSetNewCursorPos = !isCollapsed ? false : isLastBlockAtEnd ? false : true;

      // TO-DO: test all the edge cases, where user start re-recording from beggining of paragraph
      // TO-DO: fix cursot at the beginning of text edge case, where selection is the same as when editor is blurred
      const firstBlock = editorState.getCurrentContent().getFirstBlock();
      const cursorAtBeginningOfText = firstBlock.getKey() === selBlock && pos === 0;

      const { liveWordTranscript, newCursorPos } = convertEditorStateToTranscript(editorState, {
        toLiveWordData: true,
        setNewCursorPos: shouldSetNewCursorPos,
      });
      setLiveWordData({
        lastInterim: '',
        finalsBlocks: liveWordTranscript,
      });

      // TO-DO: fix cursot at the beginning of text edge case, where selection is the same as when editor is blurred
      setCursor(
        firstBlock && cursorAtBeginningOfText ? { ...newCursorPos, cursorPosition: null } : newCursorPos
      );

      setShowEditor(false);
      setLoadingEditorDetails(false);
    }
  }, [loadContentToEditor]);

  useEffect(() => {
    if (!modal.show) {
      setReplaceWordsModalProps({ se: null, selection: null, selectedPhrase: [] });
    }
  }, [modal]);

  const onCanPlayCallback = useCallback(
    (canpPlay: boolean) => {
      setAudioCanPlay(canpPlay);
    },
    [setAudioCanPlay]
  );

  const {
    duration,
    audioIsPlaying,
    setChangePlaybackRate,
    playbackRate,
    setDynamicPlaybackRate,
    togglePlayPause,
    pauseAudio,
    audioIsInError,
    audioObject,
  } = useAudioPlayer(onCanPlayCallback, loopModeOptions);

  const {
    startRecording,
    stopRecording
  } = useV3Ws();

  const {
    buildPipeline
  } = usePipeline();

  const toggleRecording = useCallback(async () => {
    if (audioIsPlaying) {
      enqueueSnackbar('Dosnemavanje ni možno med predvajanjem posnetka.', { variant: 'error' });
      return;
    }
    //asd
    if (editorMode === EditorModeEnums.RECORDING_MODE) {
      await stopRecording();
      isNewRecordingSession.current = true;
    } else if (editorMode === EditorModeEnums.EDIT_MODE || editorMode === EditorModeEnums.PLAYING_MODE) {
      const pipeline = buildPipeline()

      if (!pipeline) {
        enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' });
        dispatch(setHomeFlowState({ liveFlowInProgress: false }));
        return;
      }

      startRecording(pipeline, editorLock.sessionId);
      isNewRecordingSession.current = true;
      dispatch(setCurrentTime(0));
    }
  }, [
    audioIsPlaying,
    editorMode,
    enqueueSnackbar,
    isNewRecordingSession,
    willRecordInActiveSessionId,
    editorLock,
    dispatch,
  ]);

  const toggleSpellCheck = useCallback(() => {
    setSpellCheck((curr) => !curr);
    dispatch(setShowConfidence(!showConfidence));
  }, [setSpellCheck, dispatch, showConfidence]);

  useShortcuts({
    toggleRecording,
    togglePlayPause,
    toggleSpellCheck,
    setDynamicPlaybackrate: setDynamicPlaybackRate,
    playbackRate,
    editorMode: editorMode || EditorModeEnums.EDIT_MODE,
    duration,
    playing: audioIsPlaying,
    exception: modal.show,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');

    }
  }, [navigate]);

  useBeforeunload((event) => event.preventDefault());

  // NEEDS UPDATING FOR MAKE BLOCKS
  // useEffect(() => {
  //   if (editorMode !== EditorModeEnums.TB_UPLOAD_MODE) return;
  //   if (!duration) return;
  //   if (!(duration / 60 > 15)) return;
  //   if (!makeBlocks && tbFormat.transcript.length <= 1) {
  //     setSuggestBlocks(true);
  //   }
  // }, [duration, makeBlocks, tbFormat.transcript.length, editorMode]);

  const handleLeaveEditor = useCallback(
    async (variant: ModalVariantsEnums) => {
      if (variant === ModalVariantsEnums.EXIT) {
        // await saveCurrentSession({
        //   editorState,
        //   sessionId:
        //     willRecordInActiveSessionId !== null ? willRecordInActiveSessionId : lastSessionInfo?.sessionId,
        // });


        //history.goBack();


        /*setTimeout(() => {
          // Set audioIsPlaying MUST be false otherwise the audio will still play
          console.log("HERE PUSH BACK 3")
          history.goBack();
        }, 200);*/
      } else if (variant === ModalVariantsEnums.DISCARD) {
        const { current: recordingIds } = recordingsIdsInCurrentEditorSession;
        /*if (editorContentId.current) {
          deleteEditorContent(editorContentId.current)
            .then((r) => { })
            .catch((e) => {
              console.log(e);
              //error
            });
        }*/

        savedModalVariant.current = variant;

        if (recordingIds.length > 0) {
          try {
            // const { current: initSavedEditorContent } = savedEditorContent;
            // // saveCurrentSession({
            //   editorState:
            //     initSavedEditorContent === null ? EditorState.createEmpty() : initSavedEditorContent,
            //   sessionId:
            //     willRecordInActiveSessionId !== null
            //       ? willRecordInActiveSessionId
            //       : lastSessionInfo?.sessionId,
            // });
            const r = await Promise.all(recordingIds.map((id) => deleteRecording(id)));

            if (r) {
              r.forEach((d) => {
                if (d.status === 204) {
                  //success
                }
              });
            }
          } catch (e) {
            // TO-DO: what to do in case of en error?
          }
        }

        pauseAudio();
        dispatch(setAudioInfo({ url: '', loadNew: false }));

        /*setTimeout(() => {
          // Set audioIsPlaying MUST be false otherwise the audio will still play
          history.goBack();
        }, 200);*/
      }
      navigate('/dashboard')

    },
    [navigate, dispatch, pauseAudio, recordingsIdsInCurrentEditorSession]
  );

  const handlePlayBackRate = useCallback(() => {
    setChangePlaybackRate(true);
  }, [setChangePlaybackRate]);

  const handleDynamicPlaybackRate = useCallback(
    (playbackRate: number) => {
      setDynamicPlaybackRate(playbackRate);
    },
    [setDynamicPlaybackRate]
  );

  const handlePlaybackRateHigher = useCallback(() => {
    setDynamicPlaybackRate((dynamicPlaybackRate) => dynamicPlaybackRate + 0.1);
  }, [setDynamicPlaybackRate]);

  const handlePlaybackRateLower = useCallback(() => {
    setDynamicPlaybackRate((dynamicPlaybackRate) => dynamicPlaybackRate - 0.1);
  }, [setDynamicPlaybackRate]);

  const handleAddToDictionaryClick = useCallback(
    (word: string | undefined) => {
      setModal({ show: true, variant: ModalVariantsEnums.ADD_TO_DICT });
      setDictionaryModalProps({ open: true, word });
    },
    [setModal, setDictionaryModalProps]
  );

  const handleShowReplaceWordsModal = useCallback(() => {
    const selection = editorState.getSelection();
    const se = getAllEntitiesKeysFromSelection(editorState, selection);

    const textToReplace: IReplacementWord[] = [];

    se.forEach((en, i) => {
      const d = en.entity.getData();

      textToReplace.push({ text: d.updatedText ? d.updatedText : d.text, spaceBefore: d.spaceBefore });
    });

    if (textToReplace.length > 0) {
      setReplaceWordsModalProps({ selectedPhrase: textToReplace, se, selection });
      setModal({ show: true, variant: ModalVariantsEnums.REPLACE_WORDS });
    }
  }, [setModal, editorState, setReplaceWordsModalProps]);

  const closeDictionaryModal = useCallback(
    (saved: boolean = false) => {
      setDictionaryModalProps({ open: false, word: undefined });
      setModal({ ...modal, show: false });
      if (saved) enqueueSnackbar('Shranjevanje uspešno!', { variant: 'success' });
    },
    [setDictionaryModalProps, dispatch, setModal]
  );

  const selectionPlayAudio = useCallback(() => {
    if (loopModeOptions.isActive) {
      togglePlayPause();
      return;
    }
    const selection = editorState.getSelection();
    let startEntity = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getAnchorKey())
      .getEntityAt(selection.getStartOffset());
    let endEntity = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getAnchorKey())
      .getEntityAt(selection.getEndOffset());

    if (!startEntity) {
      startEntity = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getAnchorKey())
        .getEntityAt(selection.getStartOffset() + 1);
    }
    if (!endEntity) {
      endEntity = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getAnchorKey())
        .getEntityAt(selection.getEndOffset() - 1);
    }


    if (endEntity && startEntity) {
      const startData = editorState.getCurrentContent().getEntity(startEntity).getData();
      const endData = editorState.getCurrentContent().getEntity(endEntity).getData();

      setLoopModeOptions({
        isActive: true,
        startTime: startData.startTime,
        endTime: endData.endTime,
      });
      togglePlayPause();
    } else {
      // dispatch snack
    }
  }, [editorState, dispatch, loopModeOptions, togglePlayPause]);

  const onTimeUpdate = useCallback(
    (time: number | null) => {
      if (editorMode !== EditorModeEnums.PLAYING_MODE && editorMode !== EditorModeEnums.EDIT_MODE) return;
      if (editorMode !== EditorModeEnums.PLAYING_MODE) {
        dispatch(setEditorMode(EditorModeEnums.PLAYING_MODE));
      }

      const { liveWordTranscript: trToSearch } = convertEditorStateToTranscript(editorState, {
        toLiveWordData: true,
      });

      if (trToSearch.length === 0) {
        dispatch(setClickedTime(time));
        return;
      }

      //let foundWord = false;
      if (!time) return;

      for (let i = 0; i < trToSearch.length; i++) {
        for (let j = 0; j < trToSearch[i].words.length; j++) {
          if (
            trToSearch[i].words[j].startTime !== undefined &&
            trToSearch[i].words[j].endTime !== undefined &&
            trToSearch[i].words[j].startTime <= time &&
            trToSearch[i].words[j].endTime >= time
          ) {
            const word = document.getElementById(
              (trToSearch[i].words[j].startTime + trToSearch[i].words[j].endTime).toFixed(4)
            );

            word &&
              word.scrollIntoView({
                block: 'center',
                inline: 'center',
              });
            dispatch(setClickedTime(time));
            return;
          }
          if (j !== 0 && trToSearch[i].words[j].startTime >= time) {
            const word = document.getElementById(
              (trToSearch[i].words[j].startTime + trToSearch[i].words[j].endTime).toFixed(4)
            );

            word &&
              word.scrollIntoView({
                block: 'center',
                inline: 'center',
              });
            dispatch(setClickedTime(time));
            return;
          }
        }
      }
      dispatch(setClickedTime(time));
    },
    [tbFormat, dispatch, editorMode, editorState]
  );

  // const currentWord = useMemo(() => {
  //   const currentWord = {
  //     startTime: 0,
  //     endTime: 0,
  //   };

  //   const contentState = editorState.getCurrentContent();
  //   const contentStateConvertEdToRaw = convertToRaw(contentState);
  //   const entityMap = contentStateConvertEdToRaw.entityMap;

  //   for (var entityKey in entityMap) {
  //     const entity = entityMap[entityKey];
  //     const word = entity.data;

  //     if (word.startTime <= currentTime && word.endTime >= currentTime) {
  //       currentWord.startTime = word.startTime;
  //       currentWord.endTime = word.endTime;

  //       const selected = document.getElementById((currentWord.startTime + currentWord.endTime).toFixed(4));

  //       selected &&
  //         selected.scrollIntoView({
  //           block: 'center',
  //           inline: 'center',
  //         });
  //     }
  //   }

  //   return currentWord;
  // }, [currentTime, editorState]);

  // const currentWord = getCurrentWord();
  // const unplayedColor = 'yellow';
  // const time = Math.round(currentTime * 4.0) / 4.0;

  // const forceRenderDecorator = (currState: EditorState) => {
  //   const contentState = currState.getCurrentContent();
  //   const decorator = currState.getDecorator();
  //   const newState = EditorState.createWithContent(contentState, decorator);
  //   const newEditorState = EditorState.push(newState, contentState, 'change-block-data');
  //   setEditorState(newEditorState);
  // };

  const shouldSpeakerBeReplaced = (oldRaw: RawDraftContentState, index: number, oldSpeaker: ISpeaker) => {
    return oldRaw &&
      oldRaw.blocks[index] &&
      oldRaw.blocks[index].data !== undefined &&
      oldRaw.blocks[index].data?.speaker !== undefined &&
      oldRaw.blocks[index].data?.speaker.id === oldSpeaker.id
  }

  const replaceSpeaker = (
    newSpeaker: ISpeaker,
    oldSpeaker: ISpeaker,
    replaceAll: boolean,
    blockKey: string
  ) => {
    let oldRaw = convertToRaw(editorState.getCurrentContent());
    const startIndex = oldRaw.blocks.findIndex((b) => b.key === blockKey);

    if (startIndex === -1) return;

    if (replaceAll) {
      for (let blockIndex = 0; blockIndex < oldRaw.blocks.length; blockIndex++) {
        const blockData = oldRaw.blocks[blockIndex].data
        if (!blockData) continue;

        const speaker = blockData.speaker
        if (!speaker) continue;

        const speakerId = speaker.id
        if (!speakerId) continue;

        if (speakerId !== oldSpeaker.id) continue;

        oldRaw.blocks[blockIndex].entityRanges.forEach(range => {
          oldRaw.entityMap[range.key].data = {
            ...oldRaw.entityMap[range.key].data,

          }
        })
        oldRaw.blocks[blockIndex].data = {
          ...oldRaw.blocks[blockIndex].data,
          speaker: newSpeaker,
        };
      }
    } else {
      const blockData = oldRaw.blocks[startIndex].data
      if (!blockData) return;

      const speaker = blockData.speaker
      if (!speaker) return;

      const speakerId = speaker.id
      if (!speakerId) return;

      if (speakerId !== oldSpeaker.id) return;

      oldRaw.blocks[startIndex].data = {
        ...oldRaw.blocks[startIndex].data,
        speaker: newSpeaker,
      };
    }

    const oldRawTmpCopy = JSON.parse(JSON.stringify(oldRaw))
    const oldRawCopy: RawDraftContentBlock[] = [oldRawTmpCopy.blocks[0]]

    for (let blockIndex = 1; blockIndex < oldRaw.blocks.length; blockIndex++) {
      const blockData = oldRaw.blocks[blockIndex].data
      if (!blockData) continue;

      const speaker = blockData.speaker
      if (!speaker) continue;

      const copyData = oldRawCopy[oldRawCopy.length - 1].data
      if (!copyData) continue;

      if (speaker.id === copyData.speaker.id) {
        oldRawCopy[oldRawCopy.length - 1].text = oldRawCopy[oldRawCopy.length - 1].text + " " + oldRaw.blocks[blockIndex].text
        const lastEntityRange = oldRawCopy[oldRawCopy.length - 1].entityRanges[oldRawCopy[oldRawCopy.length - 1].entityRanges.length - 1]

        let nextOffset = lastEntityRange.offset + lastEntityRange.length
        oldRaw.blocks[blockIndex].entityRanges.forEach((range, index) => {
          const hasSpaceBefore = oldRaw.entityMap[range.key].data.spaceBefore
          const offsetObject = {
            ...range,
            offset: nextOffset,
          }

          if (hasSpaceBefore) {
            offsetObject.offset += 1
          }

          oldRaw.entityMap[range.key].data = {
            ...oldRaw.entityMap[range.key].data,
            spaceBefore: oldRaw.entityMap[range.key].data.spaceBefore ? oldRaw.entityMap[range.key].data.spaceBefore : index === 0,
          }


          oldRawCopy[oldRawCopy.length - 1].entityRanges.push(offsetObject)
          nextOffset += range.length
          if (hasSpaceBefore) {
            nextOffset += 1
          }
        })


      } else {
        oldRawCopy.push(oldRaw.blocks[blockIndex])
      }
    }

    oldRaw.blocks = oldRawCopy
    let newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');
    setEditorState(mergedState)

  };

  function addSpeakersToAllBlocks(editorState: EditorState, newSpeaker: ISpeaker) {
    let oldRaw = convertToRaw(editorState.getCurrentContent());

    oldRaw.blocks.forEach((b, i) => {
      if (!b.data || !b.data.speaker) {
        oldRaw.blocks[i].data = {
          ...oldRaw.blocks[i].data,
          speaker: newSpeaker,
          isFirstInRange: false,
        };
      }
    });

    // oldRaw.blocks.splice(index, 0, newBlock);
    const newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');

    const newSelState = EditorState.forceSelection(mergedState, editorState.getSelection());
    const ts = clearDraftSelection(newSelState, newSelState.getCurrentContent().getFirstBlock().getKey());
    handleAutoSave(ts);
    setEditorState(mergedState);
    return mergedState;
  }

  // function updateBlockData(editorState: EditorState, blockKey: string, newData: any) {
  //   let oldRaw = convertToRaw(editorState.getCurrentContent());

  //   const blockIndex = oldRaw.blocks.findIndex((b) => b.key === blockKey);

  //   if (blockIndex === -1) return editorState;
  //   oldRaw.blocks[blockIndex].data = { ...oldRaw.blocks[blockIndex].data, ...newData };

  //   // oldRaw.blocks.splice(index, 0, newBlock);
  //   const newContent = convertFromRaw(oldRaw);
  //   const mergedState = EditorState.push(editorState, newContent, 'change-block-data');
  //   setEditorState(mergedState);
  //   return mergedState;
  // }

  const toggleInlineStyles = useCallback(
    (inlineStyle: string) => {
      const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
      const currentStyle = newState.getCurrentInlineStyle();
      const stylesToAdd: string[] = [];
      currentStyle.flatMap((a) => {
        if (a) {
          stylesToAdd.push(a);
        }
        return a;
      });

      const selection = editorState.getSelection();
      const se = getAllEntitiesKeysFromSelection(editorState, selection);

      se.forEach((ent) => {
        newState.getCurrentContent().mergeEntityData(ent.entityKey, { inlineStyles: stylesToAdd });
      });

      handleAutoSave(newState);
      setEditorState(newState);
      if (editorRef) {
        editorRef?.current?.focus();
      }
    },
    [editorState, editorRef]
  );

  function insertSpeakerBlockBeforeSelectedBlock(
    editorState: EditorState,
    blockKeysRange: string[],
    newSpeaker: ISpeaker,
    untillLast?: boolean
  ) {
    let oldRaw = convertToRaw(editorState.getCurrentContent());
    const startIndex = oldRaw.blocks.findIndex((b) => b.key === blockKeysRange[0]);
    const endIndex = oldRaw.blocks.findIndex((b) => b.key === blockKeysRange[1]);

    for (let i = untillLast ? startIndex + 1 : startIndex; untillLast ? i <= endIndex : i <= endIndex; i++) {
      oldRaw.blocks[i].data = {
        ...oldRaw.blocks[i].data,
        speaker: newSpeaker,
        isFirstInRange: untillLast ? i === startIndex + 1 : oldRaw.blocks[i].key === blockKeysRange[0],
      };
    }

    const newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');

    return mergedState;
  }

  const splitBlockAtSpecificOffset = (
    editorState: EditorState,
    blockKey: string,
    offset: number,
    moveData?: boolean,
    addNewSpeakerData?: ISpeaker
  ) => {
    const contentState = editorState.getCurrentContent();

    const originalBlockData = contentState.getBlockForKey(blockKey).getData();
    const speakerData = originalBlockData.get('speaker');

    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: offset,
      focusKey: blockKey,
      focusOffset: offset,
      isBackward: false,
      hasFocus: false,
    });

    if ((speakerData && moveData) || addNewSpeakerData) {
      const newData = addNewSpeakerData
        ? originalBlockData.set('speaker', { ...addNewSpeakerData })
        : originalBlockData.set('isFirstInRange', false);

      const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), newSelection);
      const newBlockKey = splitContentState.getSelectionAfter().getStartKey();
      const newSelection1 = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: 0,
      });
      const a = Modifier.setBlockData(splitContentState, newSelection1, newData);
      const splitEditorState = EditorState.push(editorState, a, 'split-block');

      const newSelState = EditorState.forceSelection(splitEditorState, newSelection1);
      return newSelState;
    }

    const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), newSelection);

    const splitEditorState = EditorState.push(editorState, splitContentState, 'split-block');

    // to-do: return state/content && last/new created blockkey
    return splitEditorState;
  };

  //TODO: Reenable
  /*useEffect(() => {
    return () => {
      lastEditorState.current &&
        saveCurrentSession({
          editorState: lastEditorState.current,
          sessionId:
            lastWillRecordInActiveSessionId.current !== null
              ? lastWillRecordInActiveSessionId.current
              : lastSessionId.current,
        });
    };
  }, []);*/

  useEffect(() => {
    lastEditorState.current = editorState;
  }, [editorState]);
  useEffect(() => {
    if (!willRecordInActiveSessionId) return;

    lastWillRecordInActiveSessionId.current = willRecordInActiveSessionId;
  }, [willRecordInActiveSessionId]);
  useEffect(() => {
    if (!lastSessionInfo?.sessionId) return;

    lastSessionId.current = lastSessionInfo?.sessionId;
  }, [lastSessionInfo?.sessionId]);

  useEffect(() => {
    if (!showSpeakers) return;
    speakersDefined.current = true;
    setShowSpeakers(false);
    handleAutoSave(editorState);
    addSpeakersToAllBlocks(editorState, newSpeaker);
  }, [showSpeakers]);

  const handleOpenAddSpeakerModal = () => {
    dispatch(
      setSpeakersModal({
        editorSelection: editorState.getSelection(),
        modalType: SpeakersModalTypeEnum.ADD_SPEAKER_TO_SELECTION,
        showModal: true,
        speaker: null,
        blockKey: null,
      })
    );
  };

  const clearDraftSelection = (es: EditorState, blockKey: string) => {
    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: 0,
    });

    const newSelState = EditorState.forceSelection(es, newSelection);
    return newSelState;
  };

  const setSpeakerToSelection = (sel: SelectionState, speaker?: ISpeaker) => {
    if (!speaker) speaker = newSpeaker;
    const currSelection = sel || editorState.getSelection();
    const startBlockKey = currSelection.getStartKey();
    const endBlockKey = currSelection.getEndKey();
    const startOffset = currSelection.getStartOffset();
    const endOffset = currSelection.getEndOffset();
    const lastBlock = editorState.getCurrentContent().getBlockForKey(endBlockKey);
    const lastBlockLength = lastBlock.getLength();
    const endOffsetIsAtEnd = endOffset === lastBlockLength;
    const startOffsetIsAtBeginning = startOffset === 0;
    const isSameBlock = startBlockKey === endBlockKey;

    if (currSelection.isCollapsed()) return;

    if (endOffsetIsAtEnd && startOffsetIsAtBeginning) {
      const n = insertSpeakerBlockBeforeSelectedBlock(
        editorState,
        [isSameBlock ? startBlockKey : startBlockKey, endBlockKey],
        speaker
      );

      const newSelState = EditorState.forceSelection(n, editorState.getSelection());
      const ts = clearDraftSelection(newSelState, startBlockKey);
      setEditorState(ts);
      handleAutoSave(ts);
      return setShowSpeakers(true);
    } else if (startOffset === endOffset && !endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      // Never happens currenlty
    } else if (!endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      const newState1 = splitBlockAtSpecificOffset(editorState, endBlockKey, endOffset, true);
      const newState2 = splitBlockAtSpecificOffset(newState1, startBlockKey, startOffset, false, speaker);

      if (!isSameBlock) {
        const es = insertSpeakerBlockBeforeSelectedBlock(
          newState2,
          [startBlockKey, endBlockKey],
          speaker,
          true
        );

        const ts = clearDraftSelection(es, startBlockKey);
        setEditorState(ts);
        return setShowSpeakers(true);
      }

      const ts = clearDraftSelection(newState2, startBlockKey);
      setEditorState(ts);
      return setShowSpeakers(true);
    } else if (endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      const newState = splitBlockAtSpecificOffset(editorState, startBlockKey, startOffset, false, speaker);

      if (!isSameBlock) {
        const es = insertSpeakerBlockBeforeSelectedBlock(
          newState,
          [startBlockKey, endBlockKey],
          speaker,
          true
        );

        const ts = clearDraftSelection(es, startBlockKey);
        setEditorState(ts);

        return setShowSpeakers(true);
      }

      const ts = clearDraftSelection(newState, startBlockKey);
      setEditorState(ts);

      return setShowSpeakers(true);
    } else if (startOffsetIsAtBeginning && !endOffsetIsAtEnd) {
      const newState = splitBlockAtSpecificOffset(editorState, endBlockKey, endOffset, true);
      const es = insertSpeakerBlockBeforeSelectedBlock(
        newState,
        [startBlockKey, endBlockKey],
        speaker,
      );

      const ts = clearDraftSelection(es, startBlockKey);

      setEditorState(ts);
      return setShowSpeakers(true);
    }
  };

  const onEntriesClick = useCallback(() => {
    setModal({ show: true, variant: ModalVariantsEnums.ENTRIES });
  }, []);

  useEffect(() => {
    if (speakerModal?.showModal) return setModal({ show: true, variant: ModalVariantsEnums.SPEAKERS });
    if (!speakerModal?.showModal || speakerModal === null)
      return setModal((curr) => {
        return { ...curr, show: false };
      });
  }, [speakerModal]);



  useInterval(useCallback(async () => {
    if (editorLock.sessionLockKey === null || editorMode === EditorModeEnums.RECORDING_MODE) {
      return;
    }

    if (editorLock.sessionId) {
      try {
        const response = await getNewSessionLock(editorLock.sessionId, editorLock.sessionLockKey);

        dispatch(setEditorLock({ ...editorLock, sessionLockKey: response.data.key }))
      } catch (error) {
        const mappedError = error as any;
        if (mappedError.response.status === 403) {
            enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
        } else if (mappedError.response.status === 404) {
            enqueueSnackbar("Labele ni možno izbrisati - seja se je vmes spremenila.", { variant: 'error' })
        } else if (mappedError.response.status === 423) {
            enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
        } else {
            enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
        }
      }
    } else {
      enqueueSnackbar(`ID seje ne obstaja.`, {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  }, [editorLock]), editorLock.refreshAfter ? editorLock.refreshAfter * 1000 : 50 * 1000)

  //TODO: validRedirect should be set to true somewhere i dont know where but somewhere, find out.
  useEffect(() => {
    console.log("editorLock")
    console.log(editorLock)
    console.log("editorMode")
    console.log(editorMode)
  }, [editorLock, editorMode])
  
  if (!validRedirect) {
    navigate("/");
    return <></>
  }



  return (
    <>
      {/*TODO: Figure this one out.*/}
      {/*<Prompt when={!showEditor} message={"Ali ste prepričani da želite zapustiti urejevalnik?"} />*/}
      <div style={{ minHeight: '100vh', height: '100%' }}>
        {/* <div style={{ position: 'absolute', left: 50, top: 50 }}>
        <button
          onClick={() => {
            covertEditorStateToHTML(editorState);
            const es = convertToRaw(editorState.getCurrentContent());
            console.log('EditorState:', es);
          }}
        >
          LOG
        </button>
      </div> */}
        <AnimatePresence>
          {modal.show && (
            <ModalsWrapper
              modal={modal}
              setModal={setModal}
              setSpeakerToSelection={setSpeakerToSelection}
              handleLeaveEditor={handleLeaveEditor}
              setEditorState={setEditorState}
              replaceSpeaker={replaceSpeaker}
              closeDictionaryModal={closeDictionaryModal}
              dictionaryModalProps={dictionaryModalProps}
              replaceWordsModalProps={replaceWordsModalProps}
              editorState={editorState}
            />
          )}
        </AnimatePresence>

        {selectionPopoverProps.anchorRect && (
          <SelectionPopover
            toggleInlineStyles={toggleInlineStyles}
            audioIsPlaying={audioIsPlaying}
            anchorRect={selectionPopoverProps.anchorRect}
            direction={selectionPopoverProps.direction}
            selectedText={selectionPopoverProps.selectedText}
            selectionPlayAudio={selectionPlayAudio}
            closeSelectionPopover={closeSelectionPopover}
            addToDictionary={handleAddToDictionaryClick}
            handleShowReplaceWordsModal={handleShowReplaceWordsModal}
            handleOpenAddSpeakerModal={handleOpenAddSpeakerModal}
            hideDict={selectionPopoverProps.hideDict || !isModelAvailable}
          />
        )}

        <Header
          editorMode={editorMode}
          togglePlayPause={togglePlayPause}
          onEntriesClick={onEntriesClick}
          audioIsPlaying={audioIsPlaying}
          audioCanPlay={audioCanPlay}
          spellCheck={spellCheck}
          toggleSpellCheck={toggleSpellCheck}
          handlePlayBackRate={handlePlayBackRate}
          playbackRate={playbackRate}
          handleDynamicPlaybackRate={handleDynamicPlaybackRate}
          handlePlaybackRateLower={handlePlaybackRateLower}
          handlePlaybackRateHigher={handlePlaybackRateHigher}
          audioDuration={duration}
          audioIsInError={audioIsInError || isSessionDiscarded}
          audioIsInErrorMessage={isSessionDiscarded ? 'Posnetek ni na voljo, ker je bil zavržen' : undefined}
          sessionId={willRecordInActiveSessionId || lastSessionInfo?.sessionId}
        />
        {(loadingEditorDetails || (!audioCanPlay && !audioIsInError && audioObject)) &&
          editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE &&
          editorMode !== EditorModeEnums.RECORDING_MODE && (
            <LinearProgress
              style={{
                position: 'absolute',
                // bottom: 0,
                top: headerIsExtended ? 160 : 0,
                left: 0,
                right: 0,
                width: '100%',
                // backgroundColor: '#ffffff',
                // color: 'white',
              }}
              color="secondary"
            />
          )}

        <div
          id="editor_main_scroll"
          className={`editor_container_main ${headerIsExtended ? 'header_extended' : ''}`}
          style={{
            fontSize: fontSize,
          }}
        >
          <div className="editor_wrapper_main" id="editor-wrapper-main">
            <div ref={topScrollerRef} />
            {/* ADDITION OPTION TO CHANGE WORD STYLES BASED ON CURR-TIME - LESS RERENDERS  */}
            {/* {editorMode === EditorModeEnums.PLAYING_MODE && (
            <style scoped>
              {`[data-start~="${currentWord && currentWord.startTime.toString()}"] { color: red !important;
              font-weight: bold !important; }`}
              {`[data-start="${currentWord && currentWord.startTime.toString()}"] { color: #333333 !important;
              font-weight: bold !important; }`}
              {`[data-start="${
                currentWord && currentWord.startTime.toString()
              }"]+span { color: red !important;
              font-weight: bold !important; }`}
            </style>
          )} */}

            {showEditor ? (
              <CustomEditor
                editorSelectionUpdateOnMount={editorSelectionUpdateOnMount}
                ref={editorRef}
                editorState={editorState}
                handlePastedText={handlePastedText}
                setEditorState={setEditorState}
                onEditorChange={onEditorChange}
                isEditorFocused={isEditorFocused}
                handleBeforeInput={handleBeforeInput}
                customBlockStyleFn={customBlockStyleFn}
                readOnly={editorLock.sessionLockKey === null || editorMode === EditorModeEnums.RECORDING_MODE || (user ? !user.userRoles.includes(UserRoleEnums.GROUP_WRITE) : false)} // || audioIsPlaying can be removed currenlty and it works fine
              />
            ) : (
              <LiveEditor
                isNewRecordingSession={isNewRecordingSession}
                hasEditorContentChanged={hasEditorContentChanged}
              />
            )}
          </div>
        </div>

        <Footer
          toggleRecording={toggleRecording}
          setModal={setModal}
          onTimeUpdate={onTimeUpdate}
          duration={duration}
          footerTr={footerTr}
          editorState={editorState}
          handleLeaveEditor={handleLeaveEditor}
          lastSaved={lastSaved}
          canDiscard={canDiscard}
          sessionId={editorLock.sessionId}
          readOnly={editorLock.sessionLockKey === null || editorMode === EditorModeEnums.RECORDING_MODE || (user ? !user.userRoles.includes(UserRoleEnums.GROUP_WRITE) : false)}
        />
      </div>
    </>
  );
};

export default Editor;
