import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import './new_table.css';
import { AutoSizer, Column, Table, InfiniteLoader, TableCellProps, TableHeaderProps, SortDirectionType, TableRowProps, TableHeaderRowProps, OnScrollParams } from 'react-virtualized';
import { DateTime, Duration } from 'luxon';
import { IAppliedFilter, SessionState } from "../DashboardHeader/ISearch";
import { addNewLabelToSession, cancelSessionInProgress, copySession, deleteSession, getAllDataAboutSessionAdmin, getClientLabels, getEditorContentV30, getNewSessionLock, getSession, getSessionLabels, getSessionRecordings, getSessionSummary, getSessionsWithShares, getSessionsWithSharesForIds, getSessionTranscripts, patchEditorContentV30, patchSessionInfo, recreateSession, registerNewClientLabel, removeLabelFromSession, updateSessionLabel } from "../../api/SessionsService";
import { IChipData, IHistorySession, ISession} from "./IDashboard";
import { ArrowDropDownIcon } from "../Icons/ArrowDropDownIcon";
import { setAudioInfo, setDiarization, setEditorLock, setEditorMode, setIsDashboardLoading, setIsDashboardReady, setIsSessionDiscarded, setSessionName, setSessionsLabels, setSpeakerSettingsModal, setTableHeader, setTableScrollPosition, setTbFormat, setValidRedirect } from "../../redux/features/app/app";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { AccessMode, IPutSessionLock } from "../../types";
import moment from "moment";
import { paragraphTokens } from "../../shared/paragrapher";
import { convertFromVersion3ToVersion2File } from "../../api/dataConverters";
import { transformNotSavedSessionTranscriptsToLiveWordData } from "../../shared/DataConverters";
import { IV3RecievedTranscript } from "../Libraries/ILibraries";
import { convertToRaw } from "draft-js";
import { EditorModeEnums, ITableHeader, SortDirection, UserRoleEnums } from "../../redux/store/IStore";
import { API, CLIENT, SESSIONS } from "../../api/endpoints";
import { urlBackend } from "../../providers/V3WebSocketProvider";
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar, useSnackbar } from "notistack";
import { IRecordingsArray, Task } from "../Home/ISettings";
import { useDebounce } from "../../hooks/useDebounce";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Zoom from "@mui/material/Zoom";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { CancelIcon } from "../Icons/CancelIcon";
import { RestartIcon } from "../Icons/RestartIcon";
import ShareModal from "./ShareModal";
import { AddCircleOutlineOutlined, Download, Merge, RestartAlt } from "@mui/icons-material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TextField from "@mui/material/TextField";
import { DeleteLabelIcon } from "../Icons/DeleteIcon";
import usePipeline from "../../hooks/usePipeline";
import saveAs from "file-saver";
import DeletionConfirmation from "./DeletionConfirmation";
import { Tooltip } from "@mui/material";
import { IGetV30 } from "../../api/types";
import { handleHeadersWrapper } from "../../shared/tableHeaderHelpers";
import { cloneDeep } from "lodash";

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

interface ILabel {
    id: number,
    code: string,
    color: string,
    isDefault: boolean,
    isAssigned: boolean,
}
interface IExtendedLabel {
    isEnabled: boolean,
    label: ILabel;
}

type Labels = IExtendedLabel[];

const parseSloDate = (dateStr: string): Date | null => {
    const arr = dateStr.split('.');
    if (arr.length !== 3) return null;
    const numArr = arr.map((v) => Number(v));
    let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
    date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
    return date;
};
const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
    const urlParams: URLSearchParams = new URLSearchParams()

    appliedFilters.forEach((filter: IAppliedFilter) => {
        switch (filter[0].field) {
            case 'createdAt':
                if (filter.length === 3) {//user passed specific day or open interval at one side
                    let date = parseSloDate(filter[2].label);
                    if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

                    if (filter[1].field === "to") {
                        date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const dateStr = date.toISOString();

                    switch (filter[1].field) {
                        case 'to':
                            urlParams.append('created-at', `;${dateStr}`)
                            break;
                        case 'from':
                            urlParams.append('created-at', `${dateStr};`)
                            break;
                        case 'is':
                            const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
                            urlParams.append('created-at', `${dateStr};${dateEnd}`)
                            break;
                        default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
                    }
                } else if (filter.length === 5) {//user passed closed interval on both sides
                    let dateOne = parseSloDate(filter[2].label)
                    let dateTwo = parseSloDate(filter[4].label)

                    if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
                    if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

                    if (filter[1].field === "from") {
                        dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
                    } else {
                        dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
                    const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

                    urlParams.append('created-at', `${startDate};${endDate}`)
                } else throw new Error('Date filters should be of length 3 or 5.')
                break;
            case 'labels':
                let labelFilterValue = filter[2].label
                if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
                else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
                urlParams.append('label', labelFilterValue)
                break;

            case 'name':
                let namelFilterValue = filter[2].label
                if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
                else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
                urlParams.append('name', namelFilterValue)
                break;

            case 'search':
                urlParams.append('name', `${filter[1].label}`)
                break;

            case 'user':
                // admin only
                let userFilterValue = filter[2].label
                if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
                urlParams.append('created-by-username', userFilterValue)
                break;
            case 'group':
                let groupFilterValue = filter[2].label
                if (filter[1].field === "contains") groupFilterValue = `%${groupFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${groupFilterValue}%`
                urlParams.append('created-by-group', groupFilterValue)
                break;
            case 'sessionSource':
                // admin only
                let sessionSourceFilterValue = filter[2].label
                if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
                else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
                urlParams.append('source', sessionSourceFilterValue)
                break;
            case 'sessionStatus':
                // admin only
                let sessionStatusFilterValue = filter[2].label
                let sessionStatusFilterField = filter[1].field

                if (sessionStatusFilterValue === SessionState.DELETED) {
                    if (sessionStatusFilterField === "isNot") {
                        urlParams.append('is-discarded', 'false')
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('is-discarded', 'true')
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                } else {
                    if (sessionStatusFilterField === "isNot") {
                        Object.keys(SessionState).forEach(sessionState => {
                            if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                                urlParams.append('status', sessionState)
                            }
                        })
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('status', sessionStatusFilterValue)
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                }
                break;
            default:
                break;
        }
    });
    return urlParams;
};



interface IColumnResizeArguments {
    dataKey: string;
    newWidth: number;
}


interface INewTableProps {
    selectedDate: Date | undefined;
    updateData: (newData: IHistorySession[]) => void;
    data: IHistorySession[];
    mergeContentAndDownload: (prefix: string) => Promise<void>
    mergePrefixContent: (prefix: string) => Promise<void>    
    mergeDiscardedAndDiscardCurrentlyMerged: (prefix: string) => Promise<void>
}
const NewTable = ({
    selectedDate,
    data,
    updateData,
    mergeContentAndDownload,
    mergeDiscardedAndDiscardCurrentlyMerged,
    mergePrefixContent,
}: INewTableProps) => {
    const dispatch = useAppDispatch();
    const editorLock = useAppSelector(state => state.app.editorLock);
    const user = useAppSelector(state => state.app.user);
    const appliedFilters = useAppSelector(state => state.app.dashboardFilters);
    const tableScrollPosition = useAppSelector(state => state.app.tableScrollPosition);
    const tableHeaders = useAppSelector(state => state.app.tableHeaders);
    const isDashboardReady = useAppSelector(state => state.app.isDashboardReady);
    const navigate = useNavigate();

    const tableRef = useRef<Table | null>(null)


    const handleHeadersCallback = (newHeaders: ITableHeader[]) => {
        handleHeadersWrapper(newHeaders);
        setTableWidth(Object.values(newHeaders).filter(header => !header.hidden).reduce((sum, header) => sum + header.width, 0))
        dispatch(setTableHeader(newHeaders))
    }
    const handleColumnResize = useCallback((args: IColumnResizeArguments) => {
        const {
            dataKey,
            newWidth
        } = args;

        let newFullWidth = 0
        const copiedHeaders = cloneDeep(tableHeaders);
        const index = copiedHeaders.findIndex(header => header.key === dataKey)
        if (index < 0) return tableHeaders;
        copiedHeaders[index].width = Math.max(newWidth, 100);
        newFullWidth += Math.max(newWidth, 100)
        handleHeadersCallback(copiedHeaders)
    }, [tableHeaders]);

    const handleDragStart = (index: number) => (event: React.DragEvent) => {
        event.dataTransfer.setData('columnIndex', index.toString());
    };

    const handleDrop = (index: number) => (event: React.DragEvent) => {
        const draggedIndex = event.dataTransfer.getData('columnIndex');
        const newColumns = cloneDeep(tableHeaders);
        const [movedColumn] = newColumns.splice(+draggedIndex, 1);
        newColumns.splice(index, 0, movedColumn);
        handleHeadersCallback(newColumns);
    };


    const [sortState, setSortState] = useState<{ sortBy: string; sortDirection: SortDirectionType }>({ sortBy: '', sortDirection: SortDirection.desc })

    const handleSort = (sortBy: string, sortDirection: SortDirectionType) => {
        return;
        //setSortState({ sortBy, sortDirection });
    }

    const hideColumn = (columnToHide: string) => {
        if (tableHeaders.filter(header => !header.hidden).length === 1) {
            enqueueSnackbar("Prikazan mora biti vsaj en stolpec", { variant: 'info' })
            return;
        }

        const headersCopy = cloneDeep(tableHeaders)
        headersCopy.forEach(header => {
            if (columnToHide === header.key) header.hidden = true;
        })

        

        handleHeadersCallback(headersCopy);
    }

    const showColumn = (columnToShow: string) => {
        const headersCopy = cloneDeep(tableHeaders)
        headersCopy.forEach(header => {
            if (columnToShow === header.key) header.hidden = false;
        })
        

        handleHeadersCallback(headersCopy);
    }

    //TODO: Fix UPDATE GET request
    //https://dev-api-pipelines.true-bar.si/api/nlp/models/sl-SI%3AGEN%3A20221220-1129/operations/UPDATE

    const rowGetter = ({ index }) => {
        const row = data[index];
        return row;
    }


    const [hasMore, setHasMore] = useState<boolean>(true);

    const loadMoreRows = useCallback(async ({ startIndex, stopIndex }) => {
        const filters: URLSearchParams = transformFilters(appliedFilters);
        dispatch(setIsDashboardLoading(true))

        try {
            const {
                sessions,
                last
            } = await getSessionsWithShares(
                data[startIndex - 1].cursor,
                80,
                filters,
                { columnName: sortState.sortBy, type: sortState.sortDirection }
            );


            const mappedSessions: IHistorySession[] = sessions.map(session => {
                return {
                    ...session.content,
                    shares: session.shares,
                    cursor: session.cursor,
                    expanded: false,
                    tagged: false,
                    openedVersion: false,
                    openedLabels: false,
                    summary: session.summary,
                    recordings: session.recordings,
                    isHeader: false,
                    merging: false,
                }
            })

            updateData([...data, ...mappedSessions])
            setHasMore(!last)
        } catch (error) {
            const mappedError = error as any
            enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
        }
        dispatch(setIsDashboardLoading(false))
    }, [data]);

    const isRowLoaded = ({ index }) => !!data[index];


    const openAsViewer = async (historySession: IHistorySession, version?: string) => {
        if (!user) return;
        const {
            id,
            name,
            allRecordingsDiscarded,
            versionTags,
            isLocked,
            status
        } = historySession

        if (status !== SessionState.FINISHED && status !== SessionState.DELETED) {
            enqueueSnackbar("Seje, ki ni bila pravilno zaključena ni možno urejati.", { variant: 'error' })
            return;
        }

        if (typeof name === 'string') {
            dispatch(setSessionName(name));
        }
        dispatch(setIsSessionDiscarded(allRecordingsDiscarded));
        dispatch(setIsDashboardLoading(true));


        if (versionTags.length > 0) {
            const latestVersion = version ? version : versionTags.slice().sort((a, b) => b.localeCompare(a))[0];

            try {
                const r = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                const newLock = {
                    ...editorLock,
                    sessionLockKey: null,
                    sessionId: id,
                    refreshAfter: null,
                    editTicket: null,
                    versionName: latestVersion,
                }
                dispatch(setEditorLock(newLock))
                //updateLocalEditorLock(newLock)

                const { rawContentState } = JSON.parse(r.data.content);

                if (rawContentState) {
                    dispatch(
                        setTbFormat({
                            rawContentState: rawContentState,
                            sessionId: id,
                        })
                    );
                } else {
                    dispatch(
                        setTbFormat({
                            editorStateStringified: r.data.content as string,
                            sessionId: id,
                        })
                    );
                }

                dispatch(setIsDashboardLoading(false));

                dispatch(setValidRedirect(true));
                dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
    
                const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;
    
                dispatch(
                    setAudioInfo({
                        url: audioUrlPath,
                        loadNew: true,
                    })
                );
    
    
                navigate('/editor');
            } catch (error) {
                const mappedError = error as any
                if (mappedError.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (mappedError.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else if (mappedError.response.status === 409) {
                    enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                }
            }
        } else {

            let transcriptsResponse: IV3RecievedTranscript[] | null = null;
            try {
                transcriptsResponse = (await getSessionTranscripts(id)).data;
            } catch (error) {
                const mappedError = error as any
                if (mappedError.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (mappedError.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                }
            }

            if (!transcriptsResponse) return;

            const paragraphedTranscript = paragraphTokens(transcriptsResponse, undefined, historySession.recordedMs);
            const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
            const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted) //convertTranscriptToEditorState(converted[0].content)
    
            const sessionRecordingPagination = await getSessionRecordings(id);
            if (sessionRecordingPagination.data.length < 1) {
                throw new Error("Session has no recordings")
            }
    
            let recordings: IRecordingsArray | null = null;
            try {
                recordings = (await getSessionRecordings(id)).data
            } catch (error) {
                const mappedError = error as any
                if (mappedError.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                }
            }
    
            if (!recordings || recordings.length < 0) return;
    
    
            const pipeline = sessionRecordingPagination.data[0].pipeline
            let diarization = false;
            pipeline.forEach(tag => {
                if (tag.config.parameters.enableSd) {
                    diarization = tag.config.parameters.enableSd
                }
            })

            dispatch(
                setTbFormat({
                    editorState: newEditorState,
                    sessionId: id,
                })
            );
    
    
            dispatch(setValidRedirect(true));
            dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
    
            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;
    
            dispatch(
                setAudioInfo({
                    url: audioUrlPath,
                    loadNew: true,
                })
            );
    
            dispatch(setIsDashboardLoading(false))
            navigate('/editor');
        }
    }

    const openSession = async (historySession: IHistorySession, version?: string) => {
        if (!user) return;
        const {
            id,
            name,
            allRecordingsDiscarded,
            versionTags,
            isLocked,
            status
        } = historySession


        if (user && !user.userRoles.includes(UserRoleEnums.GROUP_WRITE)) {
            await openAsViewer(historySession, version);    
            return;
        }
        

        //setSessionIsOpening();
        /*if (status === SessionState.DELETED) {
            enqueueSnackbar("Seje, ki je bila izbrisana ni možno urejati.", { variant: 'error' })
            return;
        }*/

        if (status !== SessionState.FINISHED && status !== SessionState.DELETED) {
            enqueueSnackbar("Seje, ki ni bila pravilno zaključena ni možno urejati.", { variant: 'error' })
            return;
        }

        if (typeof name === 'string') {
            dispatch(setSessionName(name));
        }
        dispatch(setIsSessionDiscarded(allRecordingsDiscarded));
        dispatch(setIsDashboardLoading(true));

        if (versionTags.length > 0) {
            const latestVersion = version ? version : versionTags.slice().sort((a, b) => b.localeCompare(a))[0];

            if (isLocked || status === SessionState.DELETED) {
                try {
                    const r = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                    const newLock = {
                        ...editorLock,
                        sessionLockKey: null,
                        sessionId: id,
                        refreshAfter: null,
                        editTicket: null,
                        versionName: latestVersion,
                    }
                    dispatch(setEditorLock(newLock))
                    //updateLocalEditorLock(newLock)

                    const { rawContentState } = JSON.parse(r.data.content);

                    if (rawContentState) {
                        dispatch(
                            setTbFormat({
                                rawContentState: rawContentState,
                                sessionId: id,
                            })
                        );
                    } else {
                        dispatch(
                            setTbFormat({
                                editorStateStringified: r.data.content as string,
                                sessionId: id,
                            })
                        );
                    }
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 409) {
                        enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }
            } else {
                let lock: IPutSessionLock | null = null
                try {
                    lock = (await getNewSessionLock(id, null)).data;
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 423) {
                        try {
                            const readResponse = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                            const newLock = {
                                ...editorLock,
                                sessionLockKey: null,
                                sessionId: id,
                                refreshAfter: null,
                                editTicket: null,
                                versionName: latestVersion,
                            }
                            dispatch(setEditorLock(newLock))
                            //updateLocalEditorLock(newLock)

                            const { rawContentState } = JSON.parse(readResponse.data.content);

                            if (rawContentState) {
                                dispatch(
                                    setTbFormat({
                                        rawContentState: rawContentState,
                                        sessionId: id,
                                    })
                                );
                            } else {
                                dispatch(
                                    setTbFormat({
                                        editorStateStringified: readResponse.data.content as string,
                                        sessionId: id,
                                    })
                                );
                            }
                        } catch (error) {
                            const mappedError = error as any
                            if (mappedError.response.status === 403) {
                                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                            } else if (mappedError.response.status === 404) {
                                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                            } else if (mappedError.response.status === 409) {
                                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                            } else {
                                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                            }
                        }
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }

                if (!lock) return;

                let contentResponse: IGetV30 | null = null
                try {
                    contentResponse = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, latestVersion)).data;
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 409) {
                        enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                    } else if (mappedError.response.status === 423) {
                        enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }

                if (!contentResponse) return;

                const newLock = {
                    ...editorLock,
                    sessionLockKey: lock.key,
                    sessionId: id,
                    refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                    editTicket: contentResponse.editTicket,
                    versionName: latestVersion,
                }

                dispatch(setEditorLock(newLock))

                const { rawContentState } = JSON.parse(contentResponse.content);

                if (rawContentState) {
                    dispatch(
                        setTbFormat({
                            rawContentState: rawContentState,
                            sessionId: id,
                        })
                    );
                } else {
                    dispatch(
                        setTbFormat({
                            editorStateStringified: contentResponse.content as string,
                            sessionId: id,
                        })
                    );
                }
            }

            dispatch(setIsDashboardLoading(false));

            dispatch(setValidRedirect(true));
            dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));

            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

            dispatch(
                setAudioInfo({
                    url: audioUrlPath,
                    loadNew: true,
                })
            );


            dispatch(setIsDashboardReady(false))
            navigate('/editor');
            return;
        }


        //From Transcripts below, from content above

        let lock: IPutSessionLock | null = null;
        try {
            lock = (await getNewSessionLock(id, null)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!lock) return;


        let contentResponse: IGetV30 | null = null
        try {
            contentResponse = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, null)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 409) {
                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!contentResponse) return;

        let transcriptsResponse: IV3RecievedTranscript[] | null = null;
        try {
            transcriptsResponse = (await getSessionTranscripts(id)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!transcriptsResponse) return;

        const paragraphedTranscript = paragraphTokens(transcriptsResponse, undefined, historySession.recordedMs);
        const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
        const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted) //convertTranscriptToEditorState(converted[0].content)

        const sessionRecordingPagination = await getSessionRecordings(id);
        if (sessionRecordingPagination.data.length < 1) {
            throw new Error("Session has no recordings")
        }

        let recordings: IRecordingsArray | null = null;
        try {
            recordings = (await getSessionRecordings(id)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!recordings || recordings.length < 0) return;


        const pipeline = sessionRecordingPagination.data[0].pipeline
        let diarization = false;
        pipeline.forEach(tag => {
            if (tag.config.parameters.enableSd) {
                diarization = tag.config.parameters.enableSd
            }
        })

        if (!diarization) {
            try {
                await patchEditorContentV30(
                    { rawContentState: convertToRaw(newEditorState.getCurrentContent()) },
                    id,
                    contentResponse.editTicket,
                    lock.key,
                );
            } catch (error) {
                const mappedError = error as any
                if (mappedError.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (mappedError.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else if (mappedError.response.status === 409) {
                    enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                } else if (mappedError.response.status === 423) {
                    enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                }
            }
        }

        //if (!patchSuccess) return;

        let sessionData: ISession | null = null;
        try {
            sessionData = (await getSession(id)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!sessionData) return;

        const tag = sessionData.versionTags[0]

        let secondContent: IGetV30 | null = null;
        try {
            secondContent = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, tag)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 409) {
                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!secondContent) return;

        const latestVersion = version ? version : sessionData.versionTags.slice().sort((a, b) => b.localeCompare(a))[0];
        const newLock = {
            ...editorLock,
            sessionLockKey: lock.key,
            sessionId: id,
            refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
            editTicket: secondContent.editTicket,
            versionName: latestVersion,
        }
        dispatch(setEditorLock(newLock))

        if (diarization) {
            const newLock = {
                ...editorLock,
                sessionLockKey: lock.key,
                sessionId: id,
                refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                editTicket: contentResponse.editTicket,
                versionName: null,
            }
            dispatch(setEditorLock(newLock))
            dispatch(setDiarization(true))
        }

        dispatch(
            setTbFormat({
                editorState: newEditorState,
                sessionId: id,
            })
        );


        dispatch(setValidRedirect(true));
        dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));

        const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

        dispatch(
            setAudioInfo({
                url: audioUrlPath,
                loadNew: true,
            })
        );

        dispatch(setIsDashboardLoading(false))
        dispatch(setIsDashboardReady(false))
        navigate('/editor');
    }

    const [resetDashboard, setResetDashboard] = useState<boolean>(false)
    const restartDashboardData = () => setResetDashboard(!resetDashboard)
    const getInitialData = useCallback(async () => {
        dispatch(setIsDashboardLoading(true))

        if (!isDashboardReady) return;
        
        const filters: URLSearchParams = transformFilters(appliedFilters);
        filters.delete("created-at")

        if (!selectedDate) throw new Error(`Invalid date filter`)
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const year = selectedDate.getFullYear()

        const nameFilter = `%${year}-${month}-${day}%`
        //filters.append("created-at", `${startDate};${endDate}`)
        filters.append("name", nameFilter)
        const {
            sessions,
            last
        } = await getSessionsWithShares(
            undefined,
            2000,
            filters,
            { columnName: sortState.sortBy, type: sortState.sortDirection }
        );

        const sessionNameSet = new Set(sessions.map(session => session.content.name.split("/")[0]))
        
        if (sessionNameSet.size < 1) {
            updateData([])
            setHasMore(false);
            dispatch(setIsDashboardLoading(false))
            return
        }

        const prefixFilters: URLSearchParams = new URLSearchParams();
        sessionNameSet.forEach(name => prefixFilters.append('name', `${name}%`))

        const {
            sessions: sessionsPrefixed,
            last: lastPrefixed
        } = await getSessionsWithShares(
            undefined,
            2000,
            prefixFilters,
            { columnName: 'name', type: SortDirection.asc }
        );

        const mapped: IHistorySession[] = sessionsPrefixed.filter(session => !(session.content.isDiscarded && session.content.sources.includes("MERGED"))).map(session => {
            return {
                ...session.content,
                shares: session.shares,
                cursor: session.cursor,
                expanded: data.filter(currentSession => currentSession.id === session.content.id && currentSession.expanded && !currentSession.isHeader).length === 1,
                tagged: false,
                openedVersion: false,
                openedLabels: false,
                summary: session.summary,
                recordings: session.recordings,
                isHeader: false,
                merging: false,
            }
        }).sort((a, b) => {
            // First, sort by name
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            
            // If names are the same, sort by isDiscarded
            return (a.isDiscarded === b.isDiscarded) ? 0 : (a.isDiscarded ? 1 : -1);
        })

        const mappedResults: IHistorySession[] = []

        let previousPrefix: string = ''
        mapped.forEach(session => {
            const prefix = session.name.split("/")[0];
            if (prefix !== previousPrefix) {
                mappedResults.push({
                    ...session,
                    expanded: false,
                    isHeader: true,
                    name: prefix,
                })
                previousPrefix = prefix;
            }

            mappedResults.push(session);
        })

        updateData(mappedResults)
        setHasMore(!lastPrefixed);
        dispatch(setIsDashboardLoading(false))
    }, [data, sortState, appliedFilters, resetDashboard, selectedDate, isDashboardReady])

    useEffect(() => {
        getInitialData();
    }, [sortState, appliedFilters, resetDashboard, selectedDate, isDashboardReady]);

    useEffect(() => {
        const interval = setInterval(async () => {
            /*const rowsToBeUpdated = data.map(row => {
                const {
                    id,
                    status,
                    isLocked,
                } = row
                const shouldRefetchRow = status === SessionState.IN_QUEUE || status === SessionState.IN_PROGRESS || status === SessionState.INITIALIZING || isLocked
                if (shouldRefetchRow) return id
                return -1
            }).filter(id => id > -1)

            const updatedSessions = await getSessionsWithSharesForIds(rowsToBeUpdated)
 
            const rowsCopy = [...data]
            updatedSessions.forEach(session => {
                const rowIndex = rowsCopy.findIndex(row => row.id === session.id)
                if (rowIndex < 0) return;
                rowsCopy[rowIndex].status = session.status
            })*/

            getInitialData();

            //updateData(rowsCopy)
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [data])


    let widthBeforeResize = useRef<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const copiedHeaders = cloneDeep(tableHeaders)
            copiedHeaders.forEach(header => {
                const headerRatio = header.width / widthBeforeResize.current
                header.width = headerRatio * window.innerWidth
                widthBeforeResize.current = window.innerWidth;
            })
            handleHeadersCallback(copiedHeaders);
        };

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleRowExpand = (index: number) => {
        failedRef.current = false;
        const copy = [...data]
        copy.forEach((row, iterationIndex) => {

            if (index === iterationIndex) {
                row.expanded = !row.expanded
            } else {
                row.expanded = false
            }

        })
        updateData(copy)
    }

    const toggleRowTag = (index: number) => {
        const copy = [...data]
        copy[index].tagged = !copy[index].tagged
        updateData(copy)
    }


    
    const [tableWidth, setTableWidth] = useState<number>(Object.values(tableHeaders).filter(header => !header.hidden).reduce((sum, header) => sum + header.width, 0))
    const rowHeight = ({ index }) => {
        const rowData = data[index];
        if (!rowData) return 30;
        return rowData.expanded ? 430 : 30
    };

    const updateNotes = (newNotes: string, id: number) => {
        const copy = [...data]
        const sessionIndex = copy.findIndex(session => session.id === id)
        copy[sessionIndex].notes = newNotes;
        updateData(copy)
    }


    const [someOpened, setSomeOpened] = useState<boolean>(false)
    const openVersionModal = (historySession: IHistorySession) => {
        const copy = [...data]
        copy.forEach(session => {
            if (session.id === historySession.id) {
                session.openedVersion = !session.openedVersion;
            } else {
                session.openedVersion = false;
            }
        })

        const someOpenedNew = copy.filter(session => session.openedVersion).length > 0
        setSomeOpened(someOpenedNew)
        updateData(copy)
    }

    const openLabelModal = (historySession: IHistorySession) => {
        const copy = [...data]
        copy.forEach(session => {
            if (session.id === historySession.id) {
                session.openedLabels = !session.openedLabels;
            }
        })
        updateData(copy)
    }

    const addLabel = (id: number, label: IChipData) => {
        const rowsCopy = [...data]
        const updateRowIndex = rowsCopy.findLastIndex(row => row.id === id)
        if (updateRowIndex < 0) return;

        if (label.label.code === "Končano" && rowsCopy[updateRowIndex].versionTags.length < 1) {
            enqueueSnackbar(`Seja nima ustvarjene vsebine. Odprite sejo in poiskusite ponovno.`, {variant: "error"})
            return;
        }
        rowsCopy[updateRowIndex].labels.push(label)
        rowsCopy[updateRowIndex].labels = rowsCopy[updateRowIndex].labels.sort(function (a, b) {
            var textA = a.label.code.toUpperCase();
            var textB = b.label.code.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        updateData(rowsCopy)
        enqueueSnackbar('Labela uspešno dodana!', { variant: 'success' });
    }

    const removeLabel = (sessionId: number, labelId: number) => {
        const rowsCopy = [...data]
        const updateRowIndex = rowsCopy.findLastIndex(row => row.id === sessionId)
        if (updateRowIndex < 0) return;
        const newLabels = rowsCopy[updateRowIndex].labels.filter(label => label.label.id !== labelId)
        rowsCopy[updateRowIndex].labels = newLabels
        updateData(rowsCopy)
        enqueueSnackbar('Labela uspešno izbrisana!', { variant: 'success' });
    }

    const toggleLabel = (sessionId: number, labelId: number, isEnabled: boolean) => {
        const rowsCopy = [...data]
        const updateRowIndex = rowsCopy.findLastIndex(row => row.id === sessionId)
        const updateLabelIndex = rowsCopy[updateRowIndex].labels.findIndex(label => label.label.id === labelId)
        rowsCopy[updateRowIndex].labels[updateLabelIndex].isEnabled = isEnabled;
        updateData(rowsCopy)
    }


    const failedRef = useRef<boolean>(false);
    const checkAndFetchExpandedRows = async () => {
        if (failedRef.current) return;
        const expandedRows = data.filter(row => row.expanded);
        if (expandedRows.length !== 1) return; //Only one row should be expanded. If 0, no need to fetch, if more...should not happen because it is handled in expansion click logic. Check toggleRowExpand.
        const expandedRow = expandedRows[0];

        if (expandedRow.summary !== null && expandedRow.recordings !== null) return;

        try {
            const summaryResponse = await getSessionSummary(expandedRow.id)
            const responseRecordings = await getSessionRecordings(expandedRow.id)

            const dataCopy = [...data]
            dataCopy.forEach(row => {
                if (row.id === expandedRow.id) {
                    row.summary = summaryResponse.data.summary
                    row.recordings = responseRecordings.data
                }
            })
            updateData(dataCopy)
        } catch (error) {
            console.log(error);
            failedRef.current = true;
        }
    }

    const updateTableScrollPosition = (params: OnScrollParams) => {

        if (data.length > 0) {
            dispatch(setTableScrollPosition(params.scrollTop))
        }
    }

    useEffect(() => {
        if (tableRef.current) tableRef.current.recomputeRowHeights()
        checkAndFetchExpandedRows();
    }, [data])

    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    const mergePrefixContentMapper = async (prefix: string) => {
        try {
            await mergePrefixContent(prefix)
            restartDashboardData();
        } catch (error) {
            console.log(error);
        }
    }

    const mergeDiscardedAndDiscardCurrentlyMergedMapper = async (prefix: string) => {
        try {
            await mergeDiscardedAndDiscardCurrentlyMerged(prefix);
            restartDashboardData();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div ref={tableContainerRef} style={{ marginRight: 112, marginLeft: 112, height: '90%' }}>
                <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    rowCount={hasMore ? data.length + 1 : data.length}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ height, width }) => (
                                <div ref={scrollContainerRef} id="table-scroll-container" style={{ width }}>
                                    <Table
                                        ref={(instance) => {
                                            tableRef.current = instance; // Save the Table instance
                                            registerChild(instance); // Register the child for InfiniteLoader
                                        }}
                                        width={tableWidth}
                                        id="table"
                                        onRowsRendered={onRowsRendered}
                                        rowCount={data.length}
                                        rowGetter={rowGetter}
                                        headerClassName="table_header"
                                        sortBy={sortState.sortBy}
                                        sortDirection={sortState.sortDirection}
                                        rowHeight={rowHeight}
                                        height={height}
                                        onScroll={event => updateTableScrollPosition(event)}
                                        scrollTop={tableScrollPosition}
                                        onRowClick={props => toggleRowExpand(props.index)}
                                        rowRenderer={props => <TableRow
                                            mergeDiscardedAndDiscardCurrentlyMerged={mergeDiscardedAndDiscardCurrentlyMergedMapper}
                                            mergeContentAndDownload={mergeContentAndDownload}
                                            mergePrefixContent={mergePrefixContentMapper}
                                            openLabelModal={openLabelModal}
                                            restartDashboardData={restartDashboardData}
                                            removeLabel={removeLabel}
                                            toggleLabel={toggleLabel}
                                            addLabel={addLabel}
                                            someOpened={someOpened}
                                            openVersionModal={openVersionModal}
                                            updateNotes={updateNotes}
                                            toggleRowExpand={toggleRowExpand}
                                            toggleRowTag={toggleRowTag}
                                            tableHeaders={tableHeaders.filter(header => !header.hidden)}
                                            openSession={openSession}
                                            virtualizedProps={props}
                                        />}
                                        headerRowRenderer={props => <TableHeader tableWidth={tableWidth} hiddenColumns={tableHeaders.filter(header => header.hidden)} areSomeHidden={tableHeaders.filter(header => header.hidden).length > 0} showColumn={showColumn} hideColumn={hideColumn} sortBy={sortState.sortBy} sortDirection={sortState.sortDirection} handleColumnResize={handleColumnResize} tableHeaders={tableHeaders.filter(header => !header.hidden)} handleDragStart={handleDragStart} handleDrop={handleDrop} handleSort={handleSort} virtualizedProps={props} />}
                                        headerHeight={30}
                                    >
                                        {tableHeaders.filter(header => !header.hidden).map((header, index) => <Column
                                            label={header.label}
                                            dataKey={header.key}
                                            defaultSortDirection={SortDirection.desc}
                                            width={header.width}
                                            className="header-column" />
                                        )}
                                    </Table>
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            </div>
        </>
    )
}


interface IHeaderRowProps {
    virtualizedProps: TableHeaderRowProps;
    handleColumnResize: (args: IColumnResizeArguments) => void
    tableHeaders: ITableHeader[];
    handleDragStart: (index: number) => (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (index: number) => (event: React.DragEvent<HTMLDivElement>) => void;
    handleSort: (sortBy: string, sortDirection: SortDirectionType) => void;
    hideColumn: (columnToHide: string) => void;
    showColumn: (columnToShow: string) => void;
    sortBy: string;
    tableWidth: number;
    sortDirection: SortDirectionType;
    areSomeHidden: boolean;
    hiddenColumns: ITableHeader[];
}
const TableHeader = ({
    virtualizedProps,
    handleColumnResize,
    tableHeaders,
    handleDragStart,
    handleDrop,
    handleSort,
    sortBy,
    sortDirection,
    hideColumn,
    showColumn,
    areSomeHidden,
    tableWidth,
    hiddenColumns
}: IHeaderRowProps) => {
    const {
        columns,
        style,
    } = virtualizedProps;

    

    return (
        <div style={{ ...style, width: tableWidth }} role="row" className="ReactVirtualized__Table__headerRow">
            {columns.map((column, index) => {
                return <CustomHeaderRenderer
                    hiddenColumns={hiddenColumns}
                    showColumn={showColumn}
                    hideColumn={hideColumn}
                    handleSort={handleSort}
                    index={index}
                    handleDragStart={handleDragStart}
                    handleDrop={handleDrop}
                    tableHeaders={tableHeaders}
                    areSomeHidden={areSomeHidden}
                    handleColumnResize={handleColumnResize}
                    virtualizedProps={{
                        dataKey: tableHeaders[index].key,
                        label: tableHeaders[index].label,
                        sortBy,
                        sortDirection,
                    }} />
            })}
        </div>
    )
}
export default NewTable;

interface IHeaderProps {
    virtualizedProps: TableHeaderProps;
    handleColumnResize: (args: IColumnResizeArguments) => void
    tableHeaders: ITableHeader[];
    handleDragStart: (index: number) => (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (index: number) => (event: React.DragEvent<HTMLDivElement>) => void;
    index: number;
    handleSort: (sortBy: string, sortDirection: SortDirectionType) => void;
    hideColumn: (columnToHide: string) => void;
    hiddenColumns: ITableHeader[];
    showColumn: (columnToShow: string) => void;
    areSomeHidden: boolean;
}
const CustomHeaderRenderer = (props: IHeaderProps) => {
    const {
        handleColumnResize,
        tableHeaders,
        handleDragStart,
        handleDrop,
        index,
        virtualizedProps,
        handleSort,
        hideColumn,
        showColumn,
        areSomeHidden,
        hiddenColumns
    } = props

    const {
        columnData,
        dataKey,
        disableSort,
        label,
        sortBy,
        sortDirection,
    } = virtualizedProps;

    const isCurrentColumnSorted = sortBy === dataKey

    const [anchorElOpenHeaders, setAnchorElOpenHeaders] = useState<null | HTMLElement>(null);
    const haederSelectOpen = Boolean(anchorElOpenHeaders);

    const handleColumnAdd = (event: React.MouseEvent<HTMLElement>, key: string) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorElOpenHeaders(null);
        setIsHeadersOpened(false);
        setIsHovered(false);
        showColumn(key)
    }
    const toggleHeaderDropdown = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!anchorElOpenHeaders) {
            const target = event.currentTarget;
            setAnchorElOpenHeaders(target.parentElement)
            setIsHeadersOpened(true)
        } else {
            setAnchorElOpenHeaders(null);
            setIsHeadersOpened(false)
        }
    }

    const headerClickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (anchorElOpenHeaders) {
            setAnchorElOpenHeaders(null);
            setIsHeadersOpened(false);
            setIsHovered(false);
        }
    };

    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [headersOpened, setIsHeadersOpened] = useState<boolean>(false);

    return (
        <>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', width: tableHeaders[index].width, paddingLeft: 12, cursor: 'move' }}
                onMouseDown={() => document.body.classList.add('resizing')}
                onMouseUp={() => document.body.classList.remove('resizing')}
                draggable
                onDragStart={handleDragStart(index)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => !headersOpened ? setIsHovered(false) : {}}
                onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
                onDrop={handleDrop(index)}
            >
                <div onClick={() => handleSort(dataKey, sortDirection === SortDirection.desc ? SortDirection.asc : SortDirection.desc)} style={{ display: 'flex', flexDirection: 'row', flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                        {label}
                        {isCurrentColumnSorted && (
                            <div className={`sorting-icon${sortDirection === SortDirection.desc ? '' : ' sorting-asc-icon'}`} />
                        )}
                    </div>
                    {isHovered && (
                        <div className="header-operation-container">
                            {areSomeHidden && <button className="header-operation-button" onClick={toggleHeaderDropdown}>
                                <AddCircleOutlineOutlined style={{height: 24, width: 24}} />
                            </button>}
                            <button className="header-operation-button" onClick={event => { event.stopPropagation(); hideColumn(dataKey) }}>
                                <RemoveCircleOutlineIcon style={{height: 24, width: 24}} />
                            </button>
                        </div>
                    )}
                </div>


                <div
                    onMouseDown={(e) => {
                        e.preventDefault();
                        const startX = e.clientX;
                        const index = tableHeaders.findIndex(header => header.key === dataKey)
                        const startWidth = tableHeaders[index].width;
                        const onMouseMove = (e) => {
                            const newWidth = startWidth + (e.clientX - startX);
                            handleColumnResize({ dataKey, newWidth });
                        };
                        const onMouseUp = () => {
                            document.removeEventListener('mousemove', onMouseMove);
                            document.removeEventListener('mouseup', onMouseUp);
                            document.body.classList.remove('resizing');
                        };
                        document.addEventListener('mousemove', onMouseMove);
                        document.addEventListener('mouseup', onMouseUp);
                    }}
                    className="header-item-resizable-handle">
                    <div></div>
                </div>
            </div>


            <Popper
                id={'header-popper'}
                open={haederSelectOpen}
                anchorEl={anchorElOpenHeaders}
                placement="bottom-start"
                disablePortal={false}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent'
                        },
                    }
                ]}
                transition
            >
                {({ TransitionProps }) => (
                    <div>
                        <ClickAwayListener onClickAway={headerClickAwayHandler}>
                            <Zoom {...TransitionProps} timeout={350}>
                                <Paper
                                    elevation={3}
                                    style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                    className='roundedcornes'
                                >
                                    {hiddenColumns
                                        .map((column, index) => (
                                            <Tooltip className="version-tooltip" title={column.label} placement="top">
                                                <MenuItem className='version-option' key={column.key} onClick={event => handleColumnAdd(event, column.key)} style={{ backgroundColor: '#FFFFFF' }}>
                                                    {column.label}
                                                </MenuItem>
                                            </Tooltip>
                                        ))}
                                </Paper>
                            </Zoom>
                        </ClickAwayListener>
                    </div>
                )}
            </Popper>
        </>

    )
}




const formatDuration = (ms: number) => Duration.fromMillis(ms).toFormat("hh:mm:ss").toString();

function convertAndFormatTimestamp(isoTimestamp: string) {
    // Parse the ISO 8601 timestamp
    const date = DateTime.fromISO(isoTimestamp, { zone: 'local' });

    // Format the date according to the specified format
    const formattedDate = date.toFormat('d.M.yyyy HH:mm:ss');

    return formattedDate;
}

const translateStatusEnum = (value: SessionState, numberOfVersions: number, isGroupRead: boolean | null): string => {
    switch (value) {
        case SessionState.DELETED: return "IZBRISANO"
        case SessionState.INITIALIZING: return "INICIALIZACIJA"
        case SessionState.IN_QUEUE: return "V VRSTI"
        case SessionState.IN_PROGRESS: return "V PROCESIRANJU"
        case SessionState.FINISHED: return `${isGroupRead ? "UREDI" : "ODPRI"}${numberOfVersions > 0 ? ".V" + numberOfVersions : ""}`
        case SessionState.CANCELED: return "PREKINJENO"
        case SessionState.ERROR: return "NAPAKA"
        default: return value
    }
}

interface IRowProps {
    virtualizedProps: TableCellProps;
    openSession: (historySession: IHistorySession, version?: string) => Promise<void>;
    openVersionModal: (historySession: IHistorySession) => void;
    width: number;
    toggleRowTag: (index: number) => void;
    toggleLabel: (sessionId: number, labelId: number, isEnabled: boolean) => void;
    removeLabel: (sessionId: number, labelId: number) => void;
    openLabelModal: (historySession: IHistorySession) => void;
    mergeContentAndDownload: (prefix: string) => Promise<void>
    mergePrefixContent: (prefix: string) => Promise<void>;
    mergeDiscardedAndDiscardCurrentlyMerged: (prefix: string) => Promise<void>
}
const CustomCellRenderer = ({
    virtualizedProps,
    openSession,
    openVersionModal,
    width,
    openLabelModal,
    toggleRowTag,
    mergeContentAndDownload,
    mergePrefixContent,
    mergeDiscardedAndDiscardCurrentlyMerged
}: IRowProps) => {

    const user = useAppSelector(state => state.app.user)
    const isDashboardLoading = useAppSelector(state => state.app.isDashboardLoading);
    const {
        dataKey,
        rowData,
        cellData,
        rowIndex
    } = virtualizedProps

    const {
        name,
        versionTags,
        expanded,
        isHeader,
        isDiscarded,
        sources
    } = rowData

    const openSessionWrapper = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            await openSession(rowData)
        } catch (error) {
            console.log(error)

        }
    }

    const openVersion = async (version?: string) => {
        try {
            await openSession(rowData, version)
        } catch (error) {
            console.log(error)
        }
    }

    const [anchorElOpenVersions, setAnchorElOpenVersions] = useState<null | HTMLElement>(null);
    const versionSelectOpen = Boolean(anchorElOpenVersions);
    const versionSelectButtonId = versionSelectOpen ? 'simple-popper' : undefined;

    useEffect(() => {
        if (!rowData.openedVersion) setAnchorElOpenVersions(null);
    }, [rowData.openedVersion])

    const toggleVersionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (rowData.openedLabels) openLabelModal(rowData);
        if (!anchorElOpenVersions) {
            const target = event.currentTarget;
            setAnchorElOpenVersions(target.parentElement)
            openVersionModal(rowData);
        } else {
            openVersionModal(rowData);
            setAnchorElOpenVersions(null);
        }
    }
    // Used for when the user clicks away when the select version popover is opened
    const versionClickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (anchorElOpenVersions) {
            setAnchorElOpenVersions(null);
            openVersionModal(rowData);
        }
    };


    /*const toggleRowTagWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleRowTag(rowIndex);
    }*/

    const handleRowHeaderClick = async () => {
        if (rowData.merging || isDashboardLoading) return;
        let isOperater = user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE);

        if (isOperater) {
            await mergePrefixContent(name);
        } else {
            await mergeContentAndDownload(name);
        }
    }

    const handleReMergeAllSessions = async () => {
        if (rowData.merging || isDashboardLoading) return;
        await mergeDiscardedAndDiscardCurrentlyMerged(name)
    }



    switch (dataKey) {
        case "status":
            let className = "state_cell"
            if (cellData === SessionState.CANCELED) className += expanded ? " canceled_session_expanded" : " canceled_session"
            else if (cellData === SessionState.DELETED) className += expanded ? " deleted_session_expanded" : " deleted_session"
            else if (cellData === SessionState.ERROR) className += expanded ? " error_session_expanded" : " error_session"
            else if (cellData === SessionState.FINISHED) className += expanded ? " finished_session_expanded" : " finished_session"
            else if (cellData === SessionState.INITIALIZING) className += expanded ? " initializing_session_expanded" : " initializing_session"
            else if (cellData === SessionState.IN_PROGRESS) className += expanded ? " in_progress_session_expanded" : " in_progress_session"
            else if (cellData === SessionState.IN_QUEUE) className += expanded ? " in_queue_session_expanded" : " in_queue_session"
            
            if (isHeader) {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', opacity: rowData.merging || isDashboardLoading ? 0.5 : 1 }}>
                        <div style={{cursor: 'pointer', display: "flex", alignItems: 'center'}} onClick={handleRowHeaderClick}>

                            {user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE) ? <Tooltip placement="top" title="Združi seje"><Merge /></Tooltip> : <Tooltip placement="top" title="Prenesi seje"><Download /></Tooltip>}
                        </div>
                        {user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE) && (
                            <div style={{cursor: 'pointer', display: "flex", alignItems: 'center'}} onClick={handleReMergeAllSessions}>
                                <Tooltip placement="top" title="Ponovno združi">
                                    <RestartAlt />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )
            }
            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                    {/*<Checkbox checked={rowData.tagged} onChange={toggleRowTagWrapper} />*/}
                    <div className={className} data-testid={`status-${rowIndex}`}>
                        <div className="sessionStatusVersion" onClick={openSessionWrapper}>{translateStatusEnum(cellData, versionTags.length - 1, user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE))}</div>
                        
                        {!rowData.isDiscarded && rowData.versionTags.length > 1 && <ArrowDropDownIcon onClick={toggleVersionsDropdown} />}
                        <Popper

                            id={versionSelectButtonId}
                            open={versionSelectOpen}
                            anchorEl={anchorElOpenVersions}
                            placement="bottom-start"
                            disablePortal={false}
                            style={{ width }}
                            modifiers={[
                                {
                                    name: 'flip',
                                    enabled: true,
                                },
                                {
                                    name: 'preventOverflow',
                                    enabled: true,
                                    options: {
                                        boundariesElement: 'scrollParent'
                                    },
                                }
                            ]}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <div>
                                    <ClickAwayListener onClickAway={versionClickAwayHandler}>
                                        <Zoom {...TransitionProps} timeout={350}>
                                            <Paper
                                                elevation={3}
                                                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                                className='roundedcornes'
                                            >
                                                {versionTags
                                                    .map((isoDate, index) => (
                                                        <Tooltip className="version-tooltip" title={`${DateTime.fromISO(isoDate, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')} V${index}`} placement="top">
                                                            <MenuItem className='version-option' key={isoDate} onClick={_ => openVersion(isoDate)} style={{ backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p className='version-option-date'>{DateTime.fromISO(isoDate, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')}</p>
                                                                <p className='version-option-name'>V{index}</p>
                                                            </MenuItem>
                                                        </Tooltip>
                                                    ))}
                                            </Paper>
                                        </Zoom>
                                    </ClickAwayListener>
                                </div>
                            )}
                        </Popper>
                    </div>
                </div>
            )
        case "name":
            return (
                <div data-testid={`name-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} left_alligned_text ${isHeader ? "header_cell" : ""} ${isDiscarded && !expanded ? "discarded_cell" : ""} ${isHeader || sources.includes("MERGED") ? "bold_cell" : ""}`}>
                    {cellData}
                </div>
            )
        case "visibility":
            if (isHeader) return;
            return (
                <div data-testid={`visibility-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} left_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {cellData}
                </div>
            )
        case "labels":
            if (isHeader) return;
            return (
                <div data-testid={`labels-${rowIndex}`} className="labels_cell_content">
                    {(cellData as Labels).filter(label => label.isEnabled).map(label => {
                        return (
                            <Tooltip arrow placement="top" title={label.label.code}>
                                <p style={{ color: label.label.color, fontSize: 18, lineHeight: '10px' }}>■</p>
                            </Tooltip>
                        )
                    })}
                </div>
            )
        case "recordedMs":
            if (isHeader) return;
            return (
                <div data-testid={`recordedMs-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} right_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {formatDuration(cellData)}
                </div>
            )
        case "createdAt":
            if (isHeader) return;
            return (
                <div data-testid={`createdAt-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} right_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {convertAndFormatTimestamp(cellData)}
                </div>
            )
        case "updatedAt":
            if (isHeader) return;
            return (
                <div data-testid={`updatedAt-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} right_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {convertAndFormatTimestamp(cellData)}
                </div>
            )
        case "createdByUser":
            if (isHeader) return;
            return (
                <div data-testid={`createdByUser-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} left_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {cellData}
                </div>
            )
        case "sources":
            if (isHeader) return;
            return (
                <div data-testid={`sources-${rowIndex}`} className={`${expanded ? "expanded_cell_content" : "basic_cell_content"} left_alligned_text ${isDiscarded && !expanded ? "discarded_cell" : ""}`}>
                    {(cellData as (string | null)[]).filter(source => typeof source === "string").map(source => source.replace(/MERGED/g, "Združeno")).join(",")}
                </div>
            )
    }

    return (
        <p>
            {dataKey}
        </p>
    )
}


interface ITableRow {
    openSession: (historySession: IHistorySession, version?: string) => Promise<void>;
    openVersionModal: (historySession: IHistorySession) => void;
    virtualizedProps: TableRowProps;
    tableHeaders: ITableHeader[];
    toggleRowExpand: (index: number) => void;
    toggleRowTag: (index: number) => void;
    updateNotes: (newNotes: string, id: number) => void;
    someOpened: boolean;
    addLabel: (id: number, label: IChipData) => void;
    toggleLabel: (sessionId: number, labelId: number, isEnabled: boolean) => void;
    removeLabel: (sessionId: number, labelId: number) => void;
    restartDashboardData: () => void;
    openLabelModal: (historySession: IHistorySession) => void;
    mergeContentAndDownload: (prefix: string) => Promise<void>
    mergePrefixContent: (prefix: string) => Promise<void>;
    mergeDiscardedAndDiscardCurrentlyMerged: (prefix: string) => Promise<void>
}
const TableRow = ({
    mergeDiscardedAndDiscardCurrentlyMerged,
    openSession,
    openVersionModal,
    virtualizedProps,
    tableHeaders,
    toggleRowExpand,
    toggleRowTag,
    updateNotes,
    someOpened,
    addLabel,
    toggleLabel,
    removeLabel,
    restartDashboardData,
    openLabelModal,
    mergeContentAndDownload,
    mergePrefixContent,
}: ITableRow) => {
    const {
        columns,
        index,
        isScrolling,
        rowData,
        style,
    } = virtualizedProps

    const {
        isHeader
    } = rowData
    const totalTableWidth = Object.values(tableHeaders).reduce((sum, header) => sum + header.width, 0);

    const expandRowWrapper = () => {
        if (someOpened || isHeader) return;
        toggleRowExpand(index)
    }

    return (
        <div aria-rowindex={index} aria-label="row" tabIndex={0} role='row' style={{ ...style, display: 'flex', flexDirection: 'column', width: totalTableWidth, backgroundColor: isHeader ? "#BCBCBC" : "inherit" }} onClick={expandRowWrapper} className={`ReactVirtualized__Table__row${rowData.expanded ? 'ReactVirtualized__Table__row__expanded' : ""}`}>
            <div style={{ height: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: rowData.expanded ? "#707070" : "transparent" }}>
                {columns.map((column, columnIndex) => {
                    const key = column.key as string;
                    const tableHeader = tableHeaders[+key.charAt(key.length - 1)]
                    return (
                        <div style={{ paddingLeft: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: tableHeader.width, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: 30 }}>
                            <CustomCellRenderer mergeDiscardedAndDiscardCurrentlyMerged={mergeDiscardedAndDiscardCurrentlyMerged}  mergeContentAndDownload={mergeContentAndDownload} mergePrefixContent={mergePrefixContent} toggleRowTag={toggleRowTag} openLabelModal={openLabelModal} removeLabel={removeLabel} toggleLabel={toggleLabel} width={tableHeader.width} openVersionModal={openVersionModal} openSession={openSession} virtualizedProps={{ rowData, columnIndex, dataKey: tableHeader.key, isScrolling, rowIndex: index, cellData: rowData[tableHeader.key] }} />
                        </div>
                    )
                })}
            </div>
            {!rowData.isHeader && rowData.expanded && <ExpandedRow
                openLabelModal={openLabelModal}
                openVersionModal={openVersionModal}
                restartDashboardData={restartDashboardData}
                toggleLabel={toggleLabel}
                removeLabel={removeLabel}
                addLabel={addLabel}
                updateNotes={updateNotes}
                row={rowData}
            />}
        </div>
    )
}


interface IExpandedRow {
    row: IHistorySession,
    updateNotes: (newNotes: string, id: number) => void;
    addLabel: (id: number, label: IChipData) => void;
    toggleLabel: (sessionId: number, labelId: number, isEnabled: boolean) => void;
    removeLabel: (sessionId: number, labelId: number) => void;
    restartDashboardData: () => void;
    openVersionModal: (historySession: IHistorySession) => void;
    openLabelModal: (historySession: IHistorySession) => void;
}
const ExpandedRow = ({
    row,
    updateNotes,
    addLabel,
    toggleLabel,
    removeLabel,
    restartDashboardData,
    openVersionModal,
    openLabelModal
}: IExpandedRow) => {
    const user = useAppSelector(state => state.app.user);
    const availableLabels = useAppSelector(state => state.app.sessionsLabels);
    const activeConfiguration = useAppSelector(state => state.app.activeConfiguration);
    const speakerSettingsModal = useAppSelector(state => state.app.speakerSettingsModal);

    const [shareModal, setShareModal] = useState<boolean>(false);
    const [newLabel, setNewLabel] = useState<string>('');
    const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<boolean>(false);
    const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);
    const addButtonOpen = Boolean(anchorElAddLabel);
    const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

    const isHardDeleter = user && user.userRoles.includes(UserRoleEnums.SESSION_HARD_DELETE) || false
    const { enqueueSnackbar } = useSnackbar();

    const [notes, setNotes] = useState<string>(row.notes);

    const debouncedNotes = useDebounce(notes, 500, []);

    const dispatch = useAppDispatch();

    const {
        buildPipeline
    } = usePipeline();

    const initialRef = useRef<boolean>(true);
    useEffect(() => {
        const wrapper = async () => {
            try {
                await patchSessionInfo({ notes: debouncedNotes }, row.id)
                enqueueSnackbar("Zapiski uspešno posodobljeni.", { variant: "success" })
                updateNotes(debouncedNotes, row.id)
            } catch (e) {
                const error = e as any;
                if (error.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (error.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else if (error.response.status === 423) {
                    enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
                }
                console.log(error)
            }
        }

        !initialRef.current ? wrapper() : initialRef.current = false
    }, [debouncedNotes])

    const setLocalNotes = (event: ChangeEvent<HTMLTextAreaElement>) => setNotes(event.target.value)

    const cancelSessionProggress = async () => {
        try {
            await cancelSessionInProgress(row.id);
            restartDashboardData();
        } catch (error) {
            console.log(error)
        }
    }
    const recreateSessionCallback = async () => {
        //TODO: Add modal toggle if diarization is turned on.

        /*if (activeConfiguration.model && activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) {
            dispatch(setSpeakerSettingsModal({ visible: true, retranscription: true }));
            return;
        }*/

        retranscribe();
    }

    const retranscribe = async () => {
        try {
            const pipeline = buildPipeline();

            if (!pipeline) {
                enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' })
                return;
            }
            await recreateSession(row.id, pipeline);
            restartDashboardData();
        } catch (e) {
            const error = e as any;
            if (error.response.status === 402) {
                enqueueSnackbar("Porabili ste razpoložljivo kvoto za transkripcijo. Kontaktirajte podporo.", { variant: 'error' })
            } else if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        }
    }


    const downloadSession = () => {
        //TODO: handle error
        getAllDataAboutSessionAdmin(row.id)
            .then(
                ([
                    { data: dSession },
                    { data: dSummary },
                    {
                        data: dRecordings,
                    },
                    { data: dTranscripts },
                ]) => {
                    const printable = {
                        session: { ...dSession, ...dSummary },
                        recordings: dRecordings,
                        transcripts: dTranscripts.map((transcript, index) => ({
                            index,
                            content: transcript,
                        })),
                    };

                    var data = new Blob([JSON.stringify(printable, null, 4)], { type: 'application/json' });

                    saveAs(data, `truebar-${row.id}.json`);
                }
            )
            .catch((e) => {
                const error = e as any;
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            });
    }
    const openShare = () => setShareModal(true)
    const copySessionHandler = async () => {
        try {
            await copySession(row.id)
            enqueueSnackbar('Seja uspešno kopirana!', { variant: 'success' });
            restartDashboardData();
        } catch (e) {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        }
    }
    const toggleDeletion = () => setDeletionConfirmationModal(!deletionConfirmationModal)

    const openLabels = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (row.openedVersion) openVersionModal(row);
        if (!anchorElAddLabel) {
            const target = event.currentTarget;
            getClientLabels().then(({ data }) => {
                dispatch(setSessionsLabels(data));
                setAnchorElAddLabel(target);
            });
            openLabelModal(row)
        } else setAnchorElAddLabel(null);
    }

    const catchExpandedClickAndIgnore = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (addButtonOpen) setAnchorElAddLabel(null);
        if (!row.openedVersion) event.stopPropagation();
    }

    const clickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (anchorElAddLabel) setAnchorElAddLabel(null);
    };

    const addNewLabel = (labelId: number, code: string, color: string, event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        if (event) {
            event.stopPropagation();
        }

        if (code === "Končano" && row.versionTags.length < 1) {
            enqueueSnackbar(`Seja nima ustvarjene vsebine. Odprite sejo in poiskusite ponovno.`, {variant: "error"})
            return;
        }
        addNewLabelToSession(row.id, labelId, true).then(() => {
            addLabel(row.id, {
                isEnabled: true,
                label: {
                    id: labelId,
                    code,
                    color,
                    isAssigned: true,
                },
            })

            setNewLabel('');
            setAnchorElAddLabel(null);
        }).catch(e => {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Labele ni možno dodati - seja se je vmes spremenila.", { variant: 'error' })
            } else if (error.response.status === 409) {
                enqueueSnackbar(`Labela ${code} je že shranjena.`, { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        });
    };

    const generateColor = () => {
        return tempColours[availableLabels.length % tempColours.length];
    };

    const registerNewLabel = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        const color = generateColor();

        if (
            availableLabels.some(({ code }) => code === newLabel) ||
            row.labels.some(({ label: { code } }) => code === newLabel)
        ) {
            enqueueSnackbar(`Labela "${newLabel}" že obstaja.`, {
                variant: 'error',
                autoHideDuration: 4000,
            });

            return;
        }

        registerNewClientLabel(newLabel, color)
            .then(({ data: { id } }) => addNewLabel(id, newLabel, color))
            .catch((e) => {
                const labelId = e.response?.data?.id;

                const error = e as any;
                if (error.response.status === 409) {
                    enqueueSnackbar(`Labela ${newLabel} že obstaja.`, { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
                }

                if (labelId) {
                    setNewLabel('');
                    addNewLabelToSession(row.id, labelId, true).then(() => {
                        getSessionLabels(row.id).then(({ data }) => {
                            addLabel(data.id, data)
                        });
                    });
                }
            });
    };

    const onLabelInputclick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const toggleLabelWrapper = (labelId: number, isEnabled: boolean) => {
        updateSessionLabel(row.id, labelId, isEnabled).then(() => {
            toggleLabel(row.id, labelId, isEnabled)
        })
    }

    const removeLabelWrapper = (event: React.MouseEvent<HTMLElement>, labelIdToDelete: number) => {
        event.stopPropagation();
        removeLabelFromSession(row.id, labelIdToDelete).then(() => {
            removeLabel(row.id, labelIdToDelete)
        }).catch(e => {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Labele ni možno izbrisati - seja se je vmes spremenila.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        });
    };

    const deleteSessionHandler = async () => {
        try {
            await deleteSession(row.id, row.isDiscarded && isHardDeleter)
            const rowHeaders = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders[rowHeaderIndex]
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === row.id.toString()) {
                    row.classList.remove('selected-row-header')
                    continue;
                } else if (key === null) {
                    continue;
                }

                row.classList.remove('blurred-row-header')
            }
            restartDashboardData();
            enqueueSnackbar('Seja uspešno izbrisana!', { variant: 'success' });
        } catch (e) {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Brisanje seje ni uspelo! Kontaktirajte tehnično podporo.`)
            }
        }
    }

    useEffect(() => {
        if (addButtonOpen) {
            getClientLabels(newLabel).then(({ data }) => {
                dispatch(setSessionsLabels(data));
            });
        }
    }, [newLabel])

    useEffect(() => {
        if (!row.openedLabels) setAnchorElAddLabel(null);
    }, [row.openedLabels])

    const retranscriptionRef = useRef<boolean>(true);
    useEffect(() => {
        if (retranscriptionRef.current) {
            retranscriptionRef.current = false;
            return;
        }

        if (!speakerSettingsModal.visible && speakerSettingsModal.retranscription && row.expanded) retranscribe();
    }, [speakerSettingsModal.visible])

    const smallerIds = new Set(row.labels.map(item => item.label.id));
    const includesFinishedLabel = row.labels.filter(label => label.label.code === "Končano").length === 1
    return (
        <>
            <ShareModal
                open={shareModal}
                handleClose={setShareModal}
                modalTitle={row.name}
                sessionId={row.id}
                setSharedWithOthers={() => { }}
            />

            <DeletionConfirmation
                hardDelete={row.isDiscarded && isHardDeleter}
                open={deletionConfirmationModal}
                handleClose={toggleDeletion}
                deleteSessionCallback={deleteSessionHandler}
            />

            <Popper
                id={addButtonId}
                open={addButtonOpen}
                anchorEl={anchorElAddLabel}
                placement="right-end"
                disablePortal={false}
                style={{ zIndex: 100000 }}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent'
                        },
                    }
                ]}
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={clickAwayHandler}>
                        <Zoom {...TransitionProps} timeout={350}>
                            <Paper
                                elevation={3}
                                className="accordion-chip-popper-container roundedcornes"
                                style={{ marginLeft: '-5px' }}
                                square={false}>
                                <div className='accordion-chip-popper-wrapper'>
                                    {availableLabels.filter(label => !smallerIds.has(label.id))
                                        .map(({ id, code, color }) => (
                                            <MenuItem key={id} onClick={event => addNewLabel(id, code, color, event)}>
                                                <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                                                {code}
                                            </MenuItem>
                                        ))}

                                    {availableLabels.filter(label => !smallerIds.has(label.id)).length === 0 && (
                                        <MenuItem key='-1'>
                                            Na voljo ni nobena labela
                                        </MenuItem>
                                    )}
                                </div>

                                <div className="accordion-chip-popper-footer">
                                    <AddCircleOutlineOutlined
                                        fontSize="small"
                                        className="accordion-chip-popper-footer-add"
                                        style={{ color: generateColor() }}
                                    />
                                    <TextField
                                        fullWidth
                                        placeholder="Vnesi ime labele"
                                        value={newLabel}
                                        spellCheck={false}
                                        onChange={(e) => setNewLabel(e.target.value)}
                                        variant="outlined"
                                        className='paddingoutline'
                                        onClick={onLabelInputclick}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') registerNewLabel(ev);
                                        }}
                                    />
                                </div>
                            </Paper>
                        </Zoom>
                    </ClickAwayListener>
                )}
            </Popper>

            <div onClick={catchExpandedClickAndIgnore} style={{ display: "flex", flexDirection: 'column', height: '400px', backgroundColor: '#E6E6E6', width: '100%', borderRight: "2px solid #707070", borderLeft: "2px solid #707070" }}>
                <div style={{ flex: 1, padding: 32, display: "flex", flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                        <p className='detail_title'>Predogled</p>
                        <div className='preview-contents'>
                            <p className='preview-contents-text'>
                                {row.summary}
                            </p>
                        </div>

                        <div className='notes-container'>
                            <p className='notes-header'>Zapiski</p>
                            <textarea
                                onClick={event => { event.preventDefault(); event.stopPropagation(); }}
                                className='notes-textarea'
                                placeholder='Vnesi tekst tukaj'
                                value={notes}
                                onChange={setLocalNotes}
                            />
                        </div>

                        {row.labels.length > 0 && (
                            <div style={{marginTop: "24px"}}>
                                <p className='notes-header'>Labele</p>
                                <div className='labels-container'>
                                    {row.labels.map((label, index) => {
                                        return (
                                            <div className='label-wrapper' key={label.label.id} onClick={() => toggleLabelWrapper(label.label.id, !label.isEnabled)} style={{
                                                backgroundColor: label.isEnabled ? label.label.color : 'transparent',
                                                border: `1px solid ${label.isEnabled ? 'transparent' : label.label.color}`,
                                            }}>
                                                <p className='label-button-text' style={{ color: label.isEnabled ? '#FFF' : label.label.color }}>{label.label.code}</p>
                                                <button data-testid={`delete-label-${label.label.code}`} className='delete-label-button' onClick={(event) => removeLabelWrapper(event, label.label.id)}>
                                                    <DeleteLabelIcon fill={label.isEnabled ? "#FFF" : label.label.color} />
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='recordings-table-wrapper'>
                        <RecordingsTable recordings={row.recordings} />
                    </div>
                </div>
                <div style={{ height: 40, backgroundColor: "#CCCCCC", paddingLeft: 14, paddingRight: 14 }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Tooltip title="Uredi labele" placement="top" arrow>
                            <button onClick={openLabels} className='manage-labels-button' />
                        </Tooltip>
                        {row.status === SessionState.IN_QUEUE && (
                            <Tooltip title="Prekini ponovno transkribiranje" placement="top" arrow>
                                <button onClick={cancelSessionProggress} className='cancel-session-button'>
                                    <CancelIcon />
                                </button>
                            </Tooltip>
                        )}

                        {(row.status === SessionState.FINISHED || row.status === SessionState.ERROR || row.status === SessionState.CANCELED) && (
                            <Tooltip title="Ponovno transkribiraj" placement="top" arrow>
                                <button onClick={recreateSessionCallback} className='recreate-button'>
                                    <RestartIcon />
                                </button>
                            </Tooltip>
                        )}

                        <Tooltip title="Izvozi podrobnosti seje" placement="top" arrow>
                            <button onClick={downloadSession} className='download-button' />

                        </Tooltip>
                        <Tooltip title="Deli sejo" placement="top" arrow>
                            <button onClick={openShare} className='share-button' />
                        </Tooltip>
                        <Tooltip title="Podvoji sejo" placement="top" arrow>
                            <button onClick={copySessionHandler} className='copy-button' />
                        </Tooltip>
                        {(!row.isDiscarded || (row.isDiscarded && isHardDeleter)) && (
                            <Tooltip title="Briši sejo" placement="top" arrow>
                                <button onClick={toggleDeletion} className='delete-button' />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}


/*cellData?: any;
columnData?: any;
columnIndex: number;
dataKey: string;
isScrolling: boolean;
parent?: any;
rowData: any;
rowIndex: number;*/


enum RecordingTableHeaders {
    Number = "#",
    Recording = "Posnetek",
    Duration = "Dolžina posnetka",
    CreatedAt = "Datum kreiranja",
    /*User = "Uporabnik",*/
    Model = "Model",
    Settings = "Nastavitve",
}

interface RecordingTableProps {
    recordings: IRecordingsArray | null;
}
const RecordingsTable = ({
    recordings
}: RecordingTableProps) => {
    return (
        <table className="recordings-table">
            <thead className='recordings-table-thead'>
                <tr>
                    {Object.values(RecordingTableHeaders).map(header => {
                        let className = 'left-aligned-recording-table-header'
                        if (
                            header === RecordingTableHeaders.Duration ||
                            header === RecordingTableHeaders.Settings
                        ) {
                            className = 'right-aligned-recording-table-header'
                        }

                        return <td className={`${className} recordings-table-header-cell`}>{header}</td>
                    })}
                </tr>
            </thead>
            <tbody className='recordings-table-tbody'>
                {recordings && recordings.length > 0 && recordings.map((recording, index) => {

                    const asrPipeIndex = recording.pipeline.findIndex(pipe => pipe.task === Task.asr)
                    const model = asrPipeIndex < 0 ? "Brez ASR" : recording.pipeline[asrPipeIndex].config.tag
                    const duration = moment.duration(recording.recordedMs);

                    const hours = Math.floor(duration.asHours());
                    const minutes = Math.floor(duration.minutes());
                    const seconds = Math.floor(duration.seconds());
                    const remainingMilliseconds = Math.floor(duration.milliseconds());

                    const formatedDuration = `${hours > 0 ? hours + ':' : ''}${minutes > 0 ? minutes + ':' : ''}${seconds}.${remainingMilliseconds}`


                    let configString = ""
                    let configDescription = ""

                    const asrIndex = recording.pipeline.findIndex(stage => stage.task === Task.asr)
                    const itnIndex = recording.pipeline.findIndex(stage => stage.task === Task.itn)
                    const pcIndex = recording.pipeline.findIndex(stage => stage.task === Task.pc)
                    const tcIndex = recording.pipeline.findIndex(stage => stage.task === Task.tc)

                    if (asrIndex >= 0) {
                        const asrParameters = recording.pipeline[asrIndex].config.parameters

                        //configString += asrParameters.enableSd ? "T" : "F"
                        //configDescription += asrParameters.enableSd ? "Samodejna razpoznava govorcev: T\n" : "Samodejna razpoznava govorcev: F\n"

                        configString += asrParameters.enableInterims ? "T" : "F"
                        configDescription += asrParameters.enableInterims ? "Prikazuj delne transkripte: T\n" : "Prikazuj delne transkripte: F\n"

                        configString += asrParameters.enableUnks ? "T" : "F"
                        configDescription += asrParameters.enableUnks ? "Neznane besede: T\n" : "Unki: F\n"
                    } else {
                        configString += "F"
                        configDescription += "Samodejna razpoznava govorcev: F\n"

                        configString += "F"
                        configDescription += "Prikazuj delne transkripte: F\n"

                        configString += "F"
                        configDescription += "Neznane besede: F\n"
                    }

                    if (itnIndex >= 0) {
                        configString += "T"
                        configDescription += "Denormalizacija: T\n"
                    } else {
                        configString += "F"
                        configDescription += "Denormalizacija: F\n"
                    }

                    if (pcIndex >= 0) {
                        const pcParameters = recording.pipeline[pcIndex].config.parameters
                        configString += "T"
                        configDescription += "Samodejno postavljanje ločil: T\n"

                        configString += pcParameters.enableSplitToSentences ? "T" : "F"
                        configDescription += pcParameters.enableSplitToSentences ? "Razbijaj končne transkripte na povedi: T\n" : "Razbijaj končne transkripte na povedi: F\n"
                    } else {
                        configString += "F"
                        configDescription += "Samodejno postavljanje ločil: F\n"

                        configString += "F"
                        configDescription += "Razbijaj končne transkripte na povedi: F\n"
                    }

                    if (tcIndex >= 0) {
                        configString += "T"
                        configDescription += "Samodejno postavljanje velikih začetnic: T\n"
                    } else {
                        configString += "F"
                        configDescription += "Samodejno postavljanje velikih začetnic: F\n"
                    }

                    return (
                        <tr>
                            <td className='recordings-table-cell'>{index + 1}</td>
                            <td className='recordings-table-cell'>{recording.id}</td>
                            <td className='recordings-table-cell' style={{ textAlign: 'right' }}>{formatedDuration}</td>
                            <td className='recordings-table-cell'>{DateTime.fromISO(recording.createdAt, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')}</td>
                            <td className='recordings-table-cell'>{model}</td>
                            <td className="recordings-table-cell recording-info-tooltip">
                                <p style={{ textAlign: 'right' }}>{configString}</p>
                                <span className="tooltiptext">
                                    {configDescription.split("\n").map(descritpion => <p>{descritpion}</p>)}
                                </span>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}